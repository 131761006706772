import React, { useState } from 'react';
import DialogListComponent from '../CustomSelectArrayInput/Dialog';
import Button from '@mui/material/Button';
import { useGetList, useNotify, useRecordContext } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import { TextField, Grid, FormControlLabel, Checkbox } from '@mui/material';
import client from '../../utils/apolloClient';
import { useMutation } from 'react-query';
import { adminAddCoursesToUser } from '../../graphql/mutations/adminAddCoursesToUser';
import { useRefresh } from 'react-admin';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUserCourseButton = ({ onSuccess }) => {
  const record = useRecordContext();
  const apolloClient = client;
  const referenceList = useGetList('Course');
  const allCourses = referenceList.data || [];
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleGiftCourse = () => {
    setSelectedCourses([]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setSelectedCourses([]);
  };

  const handleAdd = (courseIds) => {
    handleClose();
    const courses = allCourses
      .filter((i) => courseIds.includes(i.id))
      .map((i) => ({
        id: i.id,
        title: i.title,
        price: 0,
        days: 0,
        feedBackPrice: 0,
        feedBackAvailable: false,
      }));
    setSelectedCourses(courses);
    setOpenConfirm(true);
  };

  const handleChange = (courseId, e) => {
    setSelectedCourses((prevCourses) =>
      prevCourses.map((course) =>
        course.id === courseId
          ? { ...course, [e.target.name]: e.target.value }
          : course
      )
    );
  };

  const addCoursesToUserCb = async () => {
    try {
      await apolloClient.mutate({
        mutation: adminAddCoursesToUser,
        variables: {
          data: {
            userId: record.id,
            courses: selectedCourses.map((i) => ({
              id: i.id,
              days: parseInt(i.days),
              price: parseFloat(i.price),
              feedBackPrice: parseFloat(i.feedBackPrice),
              feedBackAvailable: i.feedBackAvailable,
            })),
          },
        },
      });

      notify('Курсы добавлены', { type: 'success' });
      handleCloseConfirm();
      refresh();
      if (onSuccess) onSuccess();
    } catch (err) {
      notify(err.message, { type: 'error' });
    }
  };

  const { isLoading, mutate } = useMutation({
    mutationFn: addCoursesToUserCb,
  });

  const handleConfirm = () => {
    mutate();
  };

  return (
    <div>
      <Button
        onClick={handleGiftCourse}
        style={{ marginBottom: 16 }}
        variant="contained"
      >
        Добавить курс
      </Button>
      <DialogListComponent
        open={open}
        onClose={handleClose}
        reference="Course"
        label="Добавить курс"
        onSelect={handleAdd}
      />
      <Dialog
        open={openConfirm}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseConfirm}
      >
        <DialogTitle>Добавить курс</DialogTitle>
        <DialogContent>
          <ol>
            {selectedCourses.map((course) => (
              <li key={course.id}>
                <p>{course.title}</p>
                <Grid container direction="column" gap={1}>
                  <Grid item>
                    <TextField
                      fullWidth
                      name="price"
                      onChange={(e) => handleChange(course.id, e)}
                      value={course.price}
                      label="Цена"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      name="feedBackPrice"
                      onChange={(e) => handleChange(course.id, e)}
                      value={course.feedBackPrice}
                      label="Цена обратной связи"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      type="number"
                      fullWidth
                      name="days"
                      onChange={(e) => handleChange(course.id, e)}
                      value={course.days}
                      label="дней"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="feedBackAvailable"
                          onChange={(e) =>
                            handleChange(course.id, {
                              target: {
                                name: 'feedBackAvailable',
                                value: e.target.checked,
                              },
                            })
                          }
                          checked={course.feedBackAvailable}
                        />
                      }
                      label="Обратная связь доступна"
                    />
                  </Grid>
                </Grid>
              </li>
            ))}
          </ol>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Отмена</Button>
          <Button disabled={isLoading} onClick={handleConfirm}>
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddUserCourseButton;

import { gql } from '@apollo/client';
import { ReviewFragment } from '../fragments/reviewFragment';

export const updateReview = gql`
  ${ReviewFragment}
  mutation UpdateReview($id: ID!, $data: UpdateReviewInput!) {
    updateReview(id: $id, data: $data) {
      ...ReviewFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../fragments/userFragment';

export const USER_QUERY = gql`
  ${USER_FRAGMENT}
  query User($id: ID!) {
    User(id: $id) {
      ...UserFragment
    }
  }
`;

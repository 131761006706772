import { gql } from '@apollo/client';
import { ReviewFragment } from '../fragments/reviewFragment';

export const allReviews = gql`
  ${ReviewFragment}
  query AllReviews(
    $filter: ReviewsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allReviews(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...ReviewFragment
      }
      total
    }
  }
`;

import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Link,
  FunctionField,
} from 'react-admin';
import PaymentStatusField from './PaymentStatusField';
import getPriceView from '../../utils/getPriceView';

const ShowPage = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" />

      <FunctionField
        label="Amount"
        render={(record) => getPriceView(record.amount)}
      />
      <FunctionField
        label="Amount USDT"
        render={(record) => getPriceView(record.amountUSDT)}
      />
      <TextField source="currency" />
      <FunctionField
        label="Payment Provider"
        render={(record) =>
          record?.paymentDetails?.paymentProvider ? (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/PaymentProvider/${record?.paymentDetails?.paymentProvider.id}/show`}
            >
              {record?.paymentDetails?.paymentProvider.title}
            </Link>
          ) : null
        }
      />
      <TextField source="paymentDetails.description" />
      <TextField
        label="Trasnaction ID"
        source="paymentDetails.paymentProviderTrxId"
      />
      <FunctionField
        label="Transaction URL"
        render={(record) =>
          record.paymentDetails ? (
            <a
              rel="noreferrer noopener"
              target="_blank"
              href={record.paymentDetails.paymentProviderTrxUrl}
            >
              {record.paymentDetails.paymentProviderTrxUrl}
            </a>
          ) : null
        }
      />
      <PaymentStatusField source="status" />
    </SimpleShowLayout>
  </Show>
);

export default ShowPage;

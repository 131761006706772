import { gql } from '@apollo/client';
import { MediaFragment } from './mediaFragment';
import { AuthorFragment } from './authorFragment';

export const CourseFragment = gql`
  ${MediaFragment}
  ${AuthorFragment}
  fragment CourseFragment on Course {
    createdAt
    description
    id
    title
    name
    logoMediaId
    authorId
    feedBackAvailable
    feedBackPrice
    logoMedia {
      id
      src
      title
      name
    }
    author {
      ...AuthorFragment
    }
    logoMedia {
      ...MediaFragment
    }
    user {
      id
      firstName
      lastName
    }
    studentGroupId
    studentGroup {
      id
      title
      name
    }
    lessons {
      id
      createdAt
      title
    }
    prices {
      id
      courseId
      createdAt
      price
      title
      days
    }
  }
`;

import React from 'react';

import {
  Filter,
  List,
  Datagrid,
  TextField,
  TextInput,
  DateInput,
  DateField,
  NumberField,
  FunctionField,
  Link,
  BooleanField,
  ImageField,
} from 'react-admin';

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Title" source="title" defaultVal="" />
    <TextInput label="Description" source="description" defaultVal="" />
    <DateInput label="Дата создания" source="createdAt" />
  </Filter>
);

const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={props.filters || <Filters />}
      {...props}
    >
      <Datagrid
        bulkActionButtons={props?.bulkActionButtons}
        data={props?.data}
        rowClick={props?.rowClick || 'edit'}
      >
        <ImageField source="logoMedia.src" label="Logo" />
        <DateField source="createdAt" label="Дата создания" />
        <TextField source="title" label="Title" />
        <BooleanField source="feedBackAvailable" />
        <NumberField source="feedBackPrice" />
        <FunctionField
          label="Автор"
          render={(record) =>
            record.author ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/Author/${record.author.id}/show`}
              >{`${record.author.name}`}</Link>
            ) : null
          }
        />
        <FunctionField
          label="Группа"
          render={(record) =>
            record.studentGroup ? record.studentGroup.title : ''
          }
        />
        <FunctionField
          label="Уроков"
          render={(record) => record.lessons.length}
        />
      </Datagrid>
    </List>
  );
};

export default ListPage;

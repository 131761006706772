import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../fragments/userFragment';

export const USERS_QUERY = gql`
  ${USER_FRAGMENT}
  query Users(
    $filter: UsersFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allUsers(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...UserFragment
      }
      total
    }
  }
`;

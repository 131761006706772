import { FileField, FileInput, SelectInput, SimpleForm } from 'react-admin';
import { Typography } from '@mui/material';
import React from 'react';
import aclChoices from '../../choices/aclChoices';

const CreateUpdateForm = ({ title }) => {
  return (
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      <FileInput source="file" multiple={false}>
        <FileField source="src" title="title" />
      </FileInput>

      <SelectInput fullWidth source="acl" choices={aclChoices} />
    </SimpleForm>
  );
};

export default CreateUpdateForm;

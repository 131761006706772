import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Link,
  FunctionField,
  RichTextField,
} from 'react-admin';
import ShowLessonMedia from './ShowLessonMedia';

const LessonShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <RichTextField source="description" />
      <DateField source="createdAt" />
      <FunctionField
        label="Курс"
        render={(record) =>
          record.course ? (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/Course/${record.course.id}/show`}
            >
              {record.course.title}
            </Link>
          ) : null
        }
      />
      <FunctionField
        label="Medias"
        render={(record) =>
          record.medias ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              {record.medias.map((media) => (
                <ShowLessonMedia key={media.id} media={media} />
              ))}
            </div>
          ) : null
        }
      />
    </SimpleShowLayout>
  </Show>
);

export default LessonShow;

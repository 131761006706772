import { gql } from '@apollo/client';
import { CourseFragment } from '../fragments/courseFragment';

export const createCourse = gql`
  ${CourseFragment}
  mutation CreateCourse($data: CreateCourseInput!) {
    createCourse(data: $data) {
      ...CourseFragment
    }
  }
`;

import React from 'react';

import {
  Filter,
  List,
  Datagrid,
  TextField,
  TextInput,
  DateInput,
  DateField,
  Link,
  FunctionField,
} from 'react-admin';
import PropTypes from 'prop-types';
import MediaField from '../MediaField';

const Filters = (props) => (
  <Filter {...props}>
    <TextInput source="id" defaultVal="" />
    <TextInput source="src" defaultVal="" />
    <TextInput source="title" defaultVal="" />
    <TextInput source="mimeType" defaultVal="" />
    <TextInput source="acl" defaultVal="" />
    <DateInput source="createdAt" />
  </Filter>
);

const ListPage = ({ rowClick, ...props }) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={props.filters || <Filters />}
      {...props}
    >
      <Datagrid
        bulkActionButtons={props?.bulkActionButtons}
        data={props?.data}
        rowClick={rowClick}
      >
        <DateField source="createdAt" />
        <MediaField />
        <FunctionField
          render={(record) =>
            record.src ? (
              <Link
                target="_blank"
                onClick={(e) => e.stopPropagation()}
                to={record.src}
              >
                {record.src}
              </Link>
            ) : null
          }
        />
        <TextField source="title" />
        <TextField source="mimeType" />
        <TextField source="acl" />
      </Datagrid>
    </List>
  );
};

ListPage.propTypes = {
  rowClick: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
};

ListPage.defaultProps = {
  rowClick: 'show',
};

export default ListPage;

import { gql } from '@apollo/client';
import { ArticleTranslationFragment } from '../fragments/ArticleTranslationFragment';

export const updateArticleTranslation = gql`
  ${ArticleTranslationFragment}
  mutation UpdateArticleTranslation(
    $id: ID!
    $data: UpdateArticleTranslationInput!
  ) {
    updateArticleTranslation(id: $id, data: $data) {
      ...ArticleTranslationFragment
    }
  }
`;

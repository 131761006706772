import { useMutation } from 'react-query';
import fetchCallback from '../react-query/fetchCallback';
import { generateChatGptText } from '../graphql/mutations/generateChatGptText';

const cb = async (variables) => {
  const data = await fetchCallback({
    mutation: generateChatGptText,
    variables,
  });
  return data?.generateChatGptText;
};

function useGenerateChatGptText() {
  const { mutate, isLoading } = useMutation(cb);

  return {
    cb: mutate,
    loading: isLoading,
  };
}

export default useGenerateChatGptText;

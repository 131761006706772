import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import CustomSelectInput from '../CustomSelectInput';
import CustomRichTextInput from '../CustomRichTextInput';
import { useFormContext } from 'react-hook-form';

const Form = () => {
  const form = useFormContext();
  const chatGptText = `Создай виртуозное описание картины под названием ${form.getValues(
    'title'
  )} чтобы покупатель захотел ее купить, так-же дополни текст красивыми html тегами`;

  return (
    <>
      <Grid container flexDirection="column">
        <Grid item>
          <ImageInput accept="image/*" multiple source="medias" label="Medias">
            <ImageField source="src" title="title" />
          </ImageInput>
        </Grid>

        <Grid item width={{ sm: '50%' }}>
          <TextInput source="title" fullWidth />
        </Grid>

        <Grid item>
          <CustomRichTextInput
            source="description"
            fullWidth
            chatGptText={chatGptText}
          />
        </Grid>

        <Grid item width={{ sm: '50%' }}>
          <NumberInput defaultValue={1} source="quantity" fullWidth />
        </Grid>

        <Grid item width={{ sm: '50%' }}>
          <NumberInput label="Price EUR" source="price" fullWidth />
        </Grid>

        <Grid item width={{ sm: '50%' }}>
          <CustomSelectInput
            label="Product Group"
            source="productGroupId"
            reference="ProductGroup"
          />
        </Grid>

        <Grid item width={{ sm: '50%' }}>
          <CustomSelectInput
            label="Автор"
            source="authorId"
            reference="Author"
          />
        </Grid>

        <Grid container gap="24px" width={{ sm: '50%' }}>
          <Grid item flex={1}>
            <NumberInput source="width" fullWidth />
          </Grid>

          <Grid item flex={1}>
            <NumberInput source="height" fullWidth />
          </Grid>

          <Grid item flex={1}>
            <SelectInput
              fullWidth
              source="measurementUnit"
              name="measurementUnit"
              choices={[
                { id: 'mm', name: 'Міліметри' },
                { id: 'cm', name: 'Сантиметри' },
                { id: 'm', name: 'Метри' },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const CreateUpdateForm = ({ title }) => {
  return (
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Form />
    </SimpleForm>
  );
};

export default CreateUpdateForm;

import { gql } from '@apollo/client';
import { CourseFragment } from '../fragments/courseFragment';

export const course = gql`
  ${CourseFragment}
  query Course($id: ID!) {
    Course(id: $id) {
      ...CourseFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { ArticleFragment } from './ArticleFragment';

export const ArticleTranslationFragment = gql`
  ${ArticleFragment}
  fragment ArticleTranslationFragment on ArticleTranslation {
    articleId
    content
    createdAt
    id
    lng
    title
    updatedAt
    article {
      ...ArticleFragment
    }
  }
`;

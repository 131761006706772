import {
  DateField,
  FunctionField,
  Link,
  Show,
  TabbedShowLayout,
  TextField,
  WithRecord,
} from 'react-admin';
import React from 'react';
import OrderProductTab from './OrderProductTab';
import OrderCourseTab from './OrderCourseTab';
import PaymentStatusField from '../Payment/PaymentStatusField';
import getPriceView from '../../utils/getPriceView';

const ShowPage = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Заказ">
        <TextField label="ID" source="id" />
        <DateField label="Дата создания" source="createdAt" />
        <FunctionField
          label="Пользователь"
          render={(record) =>
            record.user ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/User/${record.user.id}/show`}
              >{`${record.user.firstName} ${record.user.lastName}`}</Link>
            ) : null
          }
        />
        <FunctionField
          label="Total Price"
          render={(record) =>
            `${record.payment.currency} ${getPriceView(
              record.totalPrice,
              false
            )}`
          }
        />
        <FunctionField
          label="Статус платежа"
          render={(record) =>
            record.payment ? (
              <PaymentStatusField value={record.payment.status} />
            ) : null
          }
        />
        <FunctionField
          label="Платеж"
          render={(record) =>
            record.payment ? (
              <Link to={`/Payment/${record.payment.id}/show`}>
                {record.payment.paymentDetails.description}
              </Link>
            ) : null
          }
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label={
          <WithRecord
            label="orderProducts"
            render={(record) => `Товары (${record.orderProducts.length})`}
          />
        }
      >
        <OrderProductTab />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label={
          <WithRecord
            label="orderProducts"
            render={(record) => `Курсы (${record.orderCourses.length})`}
          />
        }
      >
        <OrderCourseTab />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export default ShowPage;

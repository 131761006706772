import { gql } from '@apollo/client';
import { SPENDING_FRAGMENT } from '../fragments/spendingFragment';

export const SPENDING_QUERY = gql`
  ${SPENDING_FRAGMENT}
  query Spending($id: ID!) {
    Spending(id: $id) {
      ...SpendingFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { UserCourseFragment } from '../fragments/UserCourseFragment';

export const userCoursesByUserId = gql`
  ${UserCourseFragment}
  query UserCoursesByUserId($userId: ID!) {
    userCoursesByUserId(userId: $userId) {
      ...UserCourseFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { PaymentDetailsFragment } from './paymentDetailsFragment';

export const PaymentFragment = gql`
  ${PaymentDetailsFragment}
  fragment PaymentFragment on Payment {
    amount
    amountUSDT
    createdAt
    currency
    id
    status
    paymentDetailsId
    paymentDetails {
      ...PaymentDetailsFragment
    }
  }
`;

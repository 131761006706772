import { gql } from '@apollo/client';
import { OrderCourseFragment } from './orderCourseFragment';
import { UserCoursePaymentFragment } from './UserCoursePaymentFragment';

export const UserCourseFragment = gql`
  ${UserCoursePaymentFragment}
  ${OrderCourseFragment}
  fragment UserCourseFragment on UserCourse {
    accessEndsAt
    createdAt
    feedBackAvailable
    id
    orderCourseId
    updatedAt
    userCoursePayments {
      ...UserCoursePaymentFragment
    }
    orderCourse {
      ...OrderCourseFragment
    }
  }
`;

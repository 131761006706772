import { gql } from '@apollo/client';
import { ArticleFragment } from '../fragments/ArticleFragment';

export const updateArticle = gql`
  ${ArticleFragment}
  mutation UpdateArticle($id: ID!, $data: UpdateArticleInput!) {
    updateArticle(id: $id, data: $data) {
      ...ArticleFragment
    }
  }
`;

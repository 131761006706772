import { buildQuery as defaultBuildQuery } from 'ra-data-graphql-simple';
import { CREATE_USER_MUTATION } from '../graphql/mutations/createUser';
import { DELETE_USER_MUTATION } from '../graphql/mutations/deleteUser';
import { USERS_QUERY } from '../graphql/queries/users';
import { CREATE, DELETE, GET_LIST, GET_ONE, UPDATE } from 'react-admin';
import { UPDATE_USER_MUTATION } from '../graphql/mutations/updateUser';
import { USER_QUERY } from '../graphql/queries/user';
import { CREATE_SPENDING_MUTATION } from '../graphql/mutations/createSpending';
import { SPENDINGS_QUERY } from '../graphql/queries/spendings';
import { DELETE_SPENDING_MUTATION } from '../graphql/mutations/deleteSpending';
import { SPENDING_QUERY } from '../graphql/queries/spending';
import { PRODUCTS_QUERY } from '../graphql/queries/products';
import { CREATE_PRODUCT_MUTATION } from '../graphql/mutations/createProduct';
import { DELETE_PRODUCT_MUTATION } from '../graphql/mutations/deleteProduct';
import { PRODUCT_QUERY } from '../graphql/queries/product';
import { UPDATE_PRODUCT_MUTATION } from '../graphql/mutations/updateProduct';
import { UPDATE_STUDENT_GROUP_MUTATION } from '../graphql/mutations/updateStudentGroup';
import { STUDENT_GROUPS_QUERY } from '../graphql/queries/studentGroups';
import { CREATE_STUDENT_GROUP_MUTATION } from '../graphql/mutations/createStudentGroup';
import { DELETE_STUDENT_GROUP_MUTATION } from '../graphql/mutations/deleteStudentGroup';
import { STUDENT_GROUP_QUERY } from '../graphql/queries/studentGroup';
import { UPDATE_LESSON_MUTATION } from '../graphql/mutations/updateLesson';
import { LESSONS_QUERY } from '../graphql/queries/lessons';
import { CREATE_LESSON_MUTATION } from '../graphql/mutations/createLesson';
import { DELETE_LESSON_MUTATION } from '../graphql/mutations/deleteLesson';
import { LESSON_QUERY } from '../graphql/queries/lesson';
import { OrdersQuery } from '../graphql/queries/orders';
import { OrderQuery } from '../graphql/queries/order';
import { updateOrder } from '../graphql/mutations/updateOrder';
import { updateProductGroup } from '../graphql/mutations/updateProductGroup';
import { allProductGroups } from '../graphql/queries/productGroups';
import { createProductGroup } from '../graphql/mutations/createProductGroup';
import { deleteProductGroup } from '../graphql/mutations/deleteProductGroup';
import { productGroup } from '../graphql/queries/productGroup';
import { updateCourse } from '../graphql/mutations/updateCourse';
import { courses } from '../graphql/queries/courses';
import { createCourse } from '../graphql/mutations/createCourse';
import { deleteCourse } from '../graphql/mutations/deleteCourse';
import { course } from '../graphql/queries/course';
import { UpdateAuthor } from '../graphql/mutations/updateAuthor';
import { AllAuthors } from '../graphql/queries/authors';
import { CreateAuthor } from '../graphql/mutations/createAuthor';
import { DeleteAuthor } from '../graphql/mutations/deleteAuthor';
import { Author } from '../graphql/queries/author';
import { updateCoursePrice } from '../graphql/mutations/updateCoursePrice';
import { allCoursePrices } from '../graphql/queries/coursePrices';
import { createCoursePrice } from '../graphql/mutations/createCoursePrice';
import { deleteCoursePrice } from '../graphql/mutations/deleteCoursePrice';
import { coursePrice } from '../graphql/queries/coursePrice';
import { updateReview } from '../graphql/mutations/updateReview';
import { allReviews } from '../graphql/queries/reviews';
import { removeReview } from '../graphql/mutations/deleteReview';
import { Review } from '../graphql/queries/review';
import { allMedias } from '../graphql/queries/medias';
import { deleteMedia } from '../graphql/mutations/deleteMedia';
import { Media } from '../graphql/queries/media';
import { createMedia } from '../graphql/mutations/createMedia';
import { updatePaymentProvider } from '../graphql/mutations/updatePaymentProvider';
import { paymentProviders } from '../graphql/queries/paymentProviders';
import { createPaymentProvider } from '../graphql/mutations/createPaymentProvider';
import { deletePaymentProvider } from '../graphql/mutations/deletePaymentProvider';
import { PaymentProvider } from '../graphql/queries/paymentProvider';
import { updateArticle } from '../graphql/mutations/updateArticle';
import { allArticles } from '../graphql/queries/articles';
import { createArticle } from '../graphql/mutations/createArticle';
import { deleteArticle } from '../graphql/mutations/deleteArticle';
import { Article } from '../graphql/queries/article';
import { updateArticleTranslation } from '../graphql/mutations/updateArticleTranslation';
import { allArticleTranslations } from '../graphql/queries/articleTranslations';
import { createArticleTranslation } from '../graphql/mutations/createArticleTranslation';
import { deleteArticleTranslation } from '../graphql/mutations/deleteArticleTranslation';
import { ArticleTranslation } from '../graphql/queries/articleTranslation';
import { allPayments } from '../graphql/queries/payments';
import { Payment } from '../graphql/queries/payment';

export const buildQuery = (introspection) => (fetchType, resource, params) => {
  const builtQuery = defaultBuildQuery(introspection)(
    fetchType,
    resource,
    params
  );

  if (resource === 'Media') {
    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: allMedias,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allMedia;
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: Media,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.Media,
          };
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: createMedia,
        variables: {
          data: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createMedia,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: deleteMedia,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteMedia,
          };
        },
      };
    }
  }

  if (resource === 'Order') {
    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: OrdersQuery,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allOrders;
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: OrderQuery,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.Order,
          };
        },
      };
    }

    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: updateOrder,
        variables: {
          id: params.id,
          input: {
            status: params.data?.status,
            deliveryTrackingUrl: params.data?.deliveryTrackingUrl,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateOrder,
          };
        },
      };
    }
  }

  if (resource === 'User') {
    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: USERS_QUERY,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allUsers;
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: USER_QUERY,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.User,
          };
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: CREATE_USER_MUTATION,
        variables: {
          input: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createUser,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: DELETE_USER_MUTATION,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteUser,
          };
        },
      };
    }

    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: UPDATE_USER_MUTATION,
        variables: {
          id: params.id,
          data: {
            address: params.data.address,
            city: params.data.city,
            country: params.data.country,
            email: params.data.email,
            firstName: params.data.firstName,
            lastName: params.data.lastName,
            paymentProviderId: params.data.paymentProviderId,
            phone: params.data.phone,
            postalCode: params.data.postalCode,
            role: params.data.role,
            state: params.data.state,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateUser,
          };
        },
      };
    }
  }

  if (resource === 'Spending') {
    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: SPENDINGS_QUERY,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allSpendings;
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: CREATE_SPENDING_MUTATION,
        variables: {
          input: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createSpending,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: DELETE_SPENDING_MUTATION,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteSpending,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: SPENDING_QUERY,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.Spending,
          };
        },
      };
    }
  }

  if (resource === 'Product') {
    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: UPDATE_PRODUCT_MUTATION,
        variables: {
          id: params.id,
          data: {
            title: params.data.title,
            description: params.data.description,
            price: params.data.price,
            medias: params.data.medias,
            deleteMediaIds: params.data.deleteMediaIds,
            quantity: params.data.quantity,
            width: params.data.width,
            height: params.data.height,
            measurementUnit: params.data.measurementUnit,
            productGroupId: params.data.productGroupId,
            authorId: params.data.authorId,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateProduct,
          };
        },
      };
    }

    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: PRODUCTS_QUERY,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allProducts;
        },
      };
    }

    if (fetchType === CREATE) {
      const input = { ...params?.data };

      return {
        ...builtQuery,
        query: CREATE_PRODUCT_MUTATION,
        variables: {
          input,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createProduct,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: DELETE_PRODUCT_MUTATION,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteProduct,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: PRODUCT_QUERY,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.Product,
          };
        },
      };
    }
  }

  if (resource === 'ProductGroup') {
    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: updateProductGroup,
        variables: {
          id: params.id,
          data: {
            title: params.data.title,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateProductGroup,
          };
        },
      };
    }

    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: allProductGroups,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allProductGroups;
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: createProductGroup,
        variables: {
          data: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createProductGroup,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: deleteProductGroup,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteProductGroup,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: productGroup,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.ProductGroup,
          };
        },
      };
    }
  }

  if (resource === 'StudentGroup') {
    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: UPDATE_STUDENT_GROUP_MUTATION,
        variables: {
          id: params.id,
          data: {
            title: params.data.title,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateStudentGroup,
          };
        },
      };
    }

    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: STUDENT_GROUPS_QUERY,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allStudentGroups;
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: CREATE_STUDENT_GROUP_MUTATION,
        variables: {
          data: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createStudentGroup,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: DELETE_STUDENT_GROUP_MUTATION,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteStudentGroup,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: STUDENT_GROUP_QUERY,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.StudentGroup,
          };
        },
      };
    }
  }

  if (resource === 'Lesson') {
    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: UPDATE_LESSON_MUTATION,
        variables: {
          id: params.id,
          data: {
            description: params.data.description,
            title: params.data.title,
            medias: params.data.medias,
            deleteMediaIds: params.data.deleteMediaIds,
            courseId: params.data.courseId,
            sortingOrder: params.data.sortingOrder,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateLesson,
          };
        },
      };
    }

    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: LESSONS_QUERY,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allLessons;
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: CREATE_LESSON_MUTATION,
        variables: {
          data: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createLesson,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: DELETE_LESSON_MUTATION,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteLesson,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: LESSON_QUERY,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.Lesson,
          };
        },
      };
    }
  }

  if (resource === 'Course') {
    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: updateCourse,
        variables: {
          id: params.id,
          data: {
            description: params.data.description,
            studentGroupId: params.data.studentGroupId,
            title: params.data.title,
            logoMedia: params.data.logoMedia,
            deleteLogo: params.data.deleteLogo,
            authorId: params.data.authorId,
            feedBackAvailable: params.data.feedBackAvailable,
            feedBackPrice: params.data.feedBackPrice,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateCourse,
          };
        },
      };
    }

    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: courses,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allCourses;
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: createCourse,
        variables: {
          data: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createCourse,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: deleteCourse,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteCourse,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: course,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.Course,
          };
        },
      };
    }
  }

  if (resource === 'Author') {
    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: UpdateAuthor,
        variables: {
          id: params.id,
          data: {
            name: params.data.name,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateAuthor,
          };
        },
      };
    }

    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: AllAuthors,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allAuthors;
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: CreateAuthor,
        variables: {
          data: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createAuthor,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: DeleteAuthor,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteAuthor,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: Author,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.Author,
          };
        },
      };
    }
  }

  if (resource === 'CoursePrice') {
    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: updateCoursePrice,
        variables: {
          id: params.id,
          data: {
            courseId: params.data.courseId,
            days: params.data.days,
            price: params.data.price,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateCoursePrice,
          };
        },
      };
    }

    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: allCoursePrices,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allCoursePrices;
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: createCoursePrice,
        variables: {
          data: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createCoursePrice,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: deleteCoursePrice,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteCoursePrice,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: coursePrice,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.CoursePrice,
          };
        },
      };
    }
  }

  if (resource === 'Review') {
    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: updateReview,
        variables: {
          id: params.id,
          data: {
            approved: params.data.approved,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateReview,
          };
        },
      };
    }

    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: allReviews,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allReviews;
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: removeReview,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteReview,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: Review,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.Review,
          };
        },
      };
    }
  }

  if (resource === 'PaymentProvider') {
    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: updatePaymentProvider,
        variables: {
          id: params.id,
          data: {
            title: params.data.title,
            slug: params.data.slug,
            isEnabled: params.data.isEnabled,
            trxUrlTemplate: params.data.trxUrlTemplate,
            deleteMedia: params.data.deleteMedia,
            media: params.data.media,
            sortingOrder: params.data.sortingOrder,
            websiteUrl: params.data.websiteUrl,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updatePaymentProvider,
          };
        },
      };
    }

    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: paymentProviders,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allPaymentProviders;
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: createPaymentProvider,
        variables: {
          data: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createPaymentProvider,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: deletePaymentProvider,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deletePaymentProvider,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: PaymentProvider,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.PaymentProvider,
          };
        },
      };
    }
  }

  if (resource === 'Article') {
    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: updateArticle,
        variables: {
          id: params.id,
          data: {
            content: params.data.content,
            metaDescription: params.data.metaDescription,
            metaKeywords: params.data.metaKeywords,
            slug: params.data.slug,
            status: params.data.status,
            title: params.data.title,
            media: params.data.media,
            deleteMedia: params.data.deleteMedia,
            medias: params.data.medias,
            removeMedias: params.data.removeMedias,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateArticle,
          };
        },
      };
    }

    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: allArticles,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allArticles;
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: createArticle,
        variables: {
          data: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createArticle,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: deleteArticle,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteArticle,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: Article,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.Article,
          };
        },
      };
    }
  }

  if (resource === 'ArticleTranslation') {
    if (fetchType === UPDATE) {
      return {
        ...builtQuery,
        query: updateArticleTranslation,
        variables: {
          id: params.id,
          data: {
            content: params.data.content,
            lng: params.data.lng,
            title: params.data.title,
          },
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.updateArticleTranslation,
          };
        },
      };
    }

    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: allArticleTranslations,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allArticleTranslations;
        },
      };
    }

    if (fetchType === CREATE) {
      return {
        ...builtQuery,
        query: createArticleTranslation,
        variables: {
          data: params?.data,
        },
        parseResponse: ({ data }) => {
          return {
            data: data?.createArticleTranslation,
          };
        },
      };
    }

    if (fetchType === DELETE) {
      return {
        ...builtQuery,
        query: deleteArticleTranslation,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.deleteArticleTranslation,
          };
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: ArticleTranslation,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.ArticleTranslation,
          };
        },
      };
    }
  }

  if (resource === 'Payment') {
    if (fetchType === GET_LIST) {
      return {
        ...builtQuery,
        query: allPayments,
        variables: params,
        parseResponse: ({ data }) => {
          return data?.allPayments;
        },
      };
    }

    if (fetchType === GET_ONE) {
      return {
        ...builtQuery,
        query: Payment,
        variables: params,
        parseResponse: ({ data }) => {
          return {
            data: data?.Payment,
          };
        },
      };
    }
  }

  return builtQuery;
};

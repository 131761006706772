import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateField,
  SelectInput,
  NumberInput,
  Link,
  FunctionField,
} from 'react-admin';
import PaymentStatusField from './PaymentStatusField';
import currencyChoices from '../../choices/currencyChoices';
import paymentStatusChoices from '../../choices/paymentStatusChoices';
import CustomSelectInput from '../CustomSelectInput';
import getPriceView from '../../utils/getPriceView';

const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <TextInput source="id" />,
        <CustomSelectInput
          label="PaymentProvider"
          source="paymentProviderId"
          reference="PaymentProvider"
        />,
        <NumberInput source="amount" />,
        <SelectInput source="currency" choices={currencyChoices} fullWidth />,
        <SelectInput
          source="status"
          choices={paymentStatusChoices}
          fullWidth
        />,
      ]}
      {...props}
    >
      <Datagrid rowClick="show">
        <DateField source="createdAt" />
        <FunctionField
          label="Payment Provider"
          render={(record) =>
            record?.paymentDetails?.paymentProvider ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/PaymentProvider/${record?.paymentDetails?.paymentProvider.id}/show`}
              >
                {record?.paymentDetails?.paymentProvider.title}
              </Link>
            ) : null
          }
        />
        <TextField label="Description" source="paymentDetails.description" />
        <FunctionField
          label="Amount"
          render={(record) => getPriceView(record.amount)}
        />
        <TextField source="currency" />
        <PaymentStatusField source="status" />
      </Datagrid>
    </List>
  );
};

export default ListPage;

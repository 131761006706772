import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../fragments/userFragment';

export const CURRENT_USER_QUERY = gql`
  ${USER_FRAGMENT}
  query CurrentUser {
    currentUser {
      ...UserFragment
    }
  }
`;

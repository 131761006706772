import { gql } from '@apollo/client';
import { ProductGroupFragment } from '../fragments/productGroupFragment';

export const allProductGroups = gql`
  ${ProductGroupFragment}
  query ProductGroups(
    $filter: ProductGroupsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allProductGroups(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...ProductGroupFragment
      }
      total
    }
  }
`;

import { gql } from '@apollo/client';
import { PaymentProviderFragment } from '../fragments/paymentProviderFragment';

export const deletePaymentProvider = gql`
  ${PaymentProviderFragment}
  mutation DeletePaymentProvider($id: ID!) {
    deletePaymentProvider(id: $id) {
      ...PaymentProviderFragment
    }
  }
`;

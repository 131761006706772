import { resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import en from 'ra-language-english';
import ua from 'ra-language-ukrainian';
import de from 'ra-language-german';

const translations = { ua, en, de };

export const i18nProvider = polyglotI18nProvider(
  (locale) => (translations[locale] ? translations[locale] : translations.en),
  resolveBrowserLocale(),
  [
    { locale: 'ua', name: 'Українська' },
    { locale: 'de', name: 'Deutsch' },
    { locale: 'en', name: 'English' },
  ]
);

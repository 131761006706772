import { gql } from '@apollo/client';
import { USER_FRAGMENT } from './userFragment';

export const ReviewFragment = gql`
  ${USER_FRAGMENT}
  fragment ReviewFragment on Review {
    id
    createdAt
    entityId
    entityName
    message
    approved
    rating
    userId
    user {
      ...UserFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { STUDENT_GROUP_FRAGMENT } from '../fragments/studentGroupFragment';

export const STUDENT_GROUPS_QUERY = gql`
  ${STUDENT_GROUP_FRAGMENT}
  query StudentGroups(
    $filter: StudentGroupsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allStudentGroups(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...StudentGroupFragment
      }
      total
    }
  }
`;

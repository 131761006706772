import * as React from 'react';
import { Edit, SimpleForm, SelectInput, TextInput } from 'react-admin';
import { Typography } from '@mui/material';

/*

Processing: Once payment has been confirmed, the order moves to the "Processing" stage. This typically means that the order is being prepared for shipment.
Shipped: The order has been dispatched to the customer, but the delivery has not yet been confirmed.
Delivered: This status indicates that the order has been successfully delivered to the customer's designated address.
Returned: The customer has sent the product back for reasons like dissatisfaction with the product, damage, or incorrect item shipped.
Cancelled: The customer or the seller has decided to cancel the order. This could be due to various reasons like unavailability of the product, the customer changing their mind, or a failure to process the payment.

 */

const OrderEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Изменить заказ
      </Typography>

      <SelectInput
        fullWidth
        source="status"
        label="Статус"
        name="status"
        choices={[
          { id: 'created', name: 'Заказ создан' },
          { id: 'processing', name: 'Заказ в обработке' },
          { id: 'shipped', name: 'Заказ отправлен' },
          { id: 'delivered', name: 'Заказа доставлен' },
          { id: 'returned', name: 'Заказ возвращен' },
          { id: 'cancelled', name: 'Заказ отменен' },
        ]}
      />

      <TextInput source="deliveryTrackingUrl" fullWidth />
    </SimpleForm>
  </Edit>
);

export default OrderEdit;

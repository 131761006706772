import * as React from 'react';
import { Edit } from 'react-admin';

import CreateUpdateForm from './CreateUpdateForm';

const EditPage = () => {
  return (
    <Edit mutationMode="pessimistic">
      <CreateUpdateForm title="Edit course price" />
    </Edit>
  );
};

export default EditPage;

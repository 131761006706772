import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';

const ShowPage = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" label="Дата создания" />
      <TextField source="name" label="Имя" />
    </SimpleShowLayout>
  </Show>
);

export default ShowPage;

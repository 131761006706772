import { gql } from '@apollo/client';
import { CourseFragment } from './courseFragment';

export const CoursePriceFragment = gql`
  ${CourseFragment}
  fragment CoursePriceFragment on CoursePrice {
    id
    courseId
    createdAt
    days
    price
    title
    course {
      ...CourseFragment
    }
  }
`;

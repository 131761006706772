import { gql } from '@apollo/client';
import { PaymentFragment } from './paymentFragment';

export const UserCoursePaymentFragment = gql`
  ${PaymentFragment}
  fragment UserCoursePaymentFragment on UserCoursePayment {
    createdAt
    id
    paymentId
    updatedAt
    userCourseId
    payment {
      ...PaymentFragment
    }
  }
`;

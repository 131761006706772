import { gql } from '@apollo/client';
import { STUDENT_GROUP_FRAGMENT } from '../fragments/studentGroupFragment';

export const STUDENT_GROUP_QUERY = gql`
  ${STUDENT_GROUP_FRAGMENT}
  query StudentGroup($id: ID!) {
    StudentGroup(id: $id) {
      ...StudentGroupFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { AuthorFragment } from '../fragments/authorFragment';

export const UpdateAuthor = gql`
  ${AuthorFragment}
  mutation UpdateAuthor($id: ID!, $data: UpdateAuthorInput!) {
    updateAuthor(id: $id, data: $data) {
      ...AuthorFragment
    }
  }
`;

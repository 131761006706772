import { gql } from '@apollo/client';
import { CourseFragment } from '../fragments/courseFragment';

export const courses = gql`
  ${CourseFragment}
  query AllCourses(
    $filter: CoursesFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allCourses(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...CourseFragment
      }
      total
    }
  }
`;

import { gql } from '@apollo/client';

export const DELETE_SPENDING_MUTATION = gql`
  mutation DeleteSpending($id: ID!) {
    deleteSpending(id: $id) {
      description
      amount
      currency
      user {
        id
        firstName
        lastName
      }
      createdAt
    }
  }
`;

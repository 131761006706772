import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  FunctionField,
  Link,
} from 'react-admin';

const ShowPage = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" label="Дата" />
      <TextField source="title" label="Назва" />

      <FunctionField
        label="Користувач"
        render={(record) =>
          record.user ? (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/User/${record.user.id}/show`}
            >{`${record.user.firstName} ${record.user.lastName}`}</Link>
          ) : null
        }
      />
    </SimpleShowLayout>
  </Show>
);

export default ShowPage;

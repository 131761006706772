import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { Button, Card, Grid, TextField } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { LOGIN_GOOGLE_USER_MUTATION } from '../graphql/mutations/loginGoogleUser';
import { GraphQLClient } from 'graphql-request';

const MyLoginPage = () => {
  const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_URL);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ email, password }).catch(() =>
      notify('Invalid email or password', { type: 'error' })
    );
  };

  const handleNotifyGoogleLoginFailed = () => {
    notify('Google Login Failed', { type: 'error' });
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const {
        loginGoogleUser: { access_token },
      } = await graphQLClient.request(LOGIN_GOOGLE_USER_MUTATION, {
        input: { credential: credentialResponse.credential },
      });

      localStorage.setItem('access_token', access_token);

      window.location.href = '/';
    } catch (e) {
      handleNotifyGoogleLoginFailed();
    }
  };

  return (
    <div style={{ height: '100vh' }}>
      <Grid style={{ paddingTop: 48 }} container justifyContent="center">
        <Card style={{ padding: 24 }}>
          <Grid container direction="column" gap={1}>
            <TextField
              label="Ел. пошта"
              variant="outlined"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              type="password"
              label="Пароль"
              variant="outlined"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button onClick={handleSubmit} variant="contained">
              Авторизація
            </Button>
            <GoogleLogin
              flow=""
              onSuccess={handleGoogleLoginSuccess}
              onError={handleNotifyGoogleLoginFailed}
            />
          </Grid>
        </Card>
      </Grid>
    </div>
  );
};

export default MyLoginPage;

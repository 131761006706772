import React from 'react';
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  ImageButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
} from 'ra-input-rich-text';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useNotify } from 'react-admin';
import useGenerateChatGptText from '../hooks/useGenerateChatGptText';

const CustomRichTextInputToolbar = ({
  source,
  size = 'small',
  chatGtpBtnChildren = 'Создать описание ИИ',
  chatGptText,
}) => {
  const generateChatGptText = useGenerateChatGptText();
  const form = useFormContext();
  const notify = useNotify();

  const handleChatGpt = async () => {
    generateChatGptText.cb(
      { text: chatGptText },
      {
        onSuccess: (value) => {
          form.setValue(source, value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        },
        onError: (err) => {
          notify(err.message, { type: 'error' });
        },
      }
    );
  };

  return (
    <Grid container alignItems="center" gap="0.5em">
      <LevelSelect size={size} />
      <FormatButtons size={size} />
      <ColorButtons size={size} />
      <AlignmentButtons size={size} />
      <ListButtons size={size} />
      <LinkButtons size={size} />
      <ImageButtons size={size} />
      <QuoteButtons size={size} />
      <ClearButtons size={size} />
      <Button
        disabled={generateChatGptText.loading}
        variant="contained"
        size="small"
        onClick={handleChatGpt}
      >
        {chatGtpBtnChildren}
      </Button>
    </Grid>
  );
};

export default CustomRichTextInputToolbar;

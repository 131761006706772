import { gql } from '@apollo/client';
import { PRODUCT_FRAGMENT } from '../fragments/productFragment';

export const PRODUCT_QUERY = gql`
  ${PRODUCT_FRAGMENT}
  query Product($id: ID!) {
    Product(id: $id) {
      ...ProductFragment
    }
  }
`;

import OrderIcon from '@mui/icons-material/ViewList';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PaletteIcon from '@mui/icons-material/Palette';
import GroupIcon from '@mui/icons-material/Group';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Face3Icon from '@mui/icons-material/Face3';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ArticleIcon from '@mui/icons-material/Article';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SavingsIcon from '@mui/icons-material/Savings';
import OrderList from '../components/Order/List';
import OrderShow from '../components/Order/Show';
import OrderEdit from '../components/Order/Edit';
import MediaCreate from '../components/Media/MediaCreate';
import MediaList from '../components/Media/List';
import MediaShow from '../components/Media/MediaShow';
import ProductCreate from '../components/Product/ProductCreate';
import ProductList from '../components/Product/List';
import ProductShow from '../components/Product/ProductShow';
import ProductEdit from '../components/Product/ProductEdit';
import UserCreate from '../components/User/UserCreate';
import UserEdit from '../components/User/UserEdit';
import UserList from '../components/User/List';
import UserShow from '../components/User/UserShow';
import StudentGroupCreate from '../components/StudentGroup/StudentGroupCreate';
import StudentGroupEdit from '../components/StudentGroup/StudentGroupEdit';
import StudentGroupList from '../components/StudentGroup/List';
import StudentGroupShow from '../components/StudentGroup/StudentGroupShow';
import ProductGroupCreate from '../components/ProductGroup/Create';
import ProductGroupEdit from '../components/ProductGroup/Edit';
import ProductGroupList from '../components/ProductGroup/List';
import ProductGroupShow from '../components/ProductGroup/Show';
import SpendingCreate from '../components/Spending/SpendingCreate';
import SpendingList from '../components/Spending/List';
import SpendingShow from '../components/Spending/SpendingShow';
import LessonCreate from '../components/Lesson/LessonCreate';
import LessonEdit from '../components/Lesson/LessonEdit';
import LessonList from '../components/Lesson/List';
import LessonShow from '../components/Lesson/LessonShow';
import CourseCreate from '../components/Course/Create';
import CourseEdit from '../components/Course/Edit';
import CourseList from '../components/Course/List';
import CourseShow from '../components/Course/Show';
import CoursePriceCreate from '../components/CoursePrice/Create';
import CoursePriceEdit from '../components/CoursePrice/Edit';
import CoursePriceList from '../components/CoursePrice/List';
import CoursePriceShow from '../components/CoursePrice/Show';
import AuthorCreate from '../components/Author/Create';
import AuthorEdit from '../components/Author/Edit';
import AuthorList from '../components/Author/List';
import AuthorShow from '../components/Author/Show';
import ReviewEdit from '../components/Review/Edit';
import ReviewList from '../components/Review/List';
import ReviewShow from '../components/Review/Show';
import PaymentProviderCreate from '../components/PaymentProvider/Create';
import PaymentProviderEdit from '../components/PaymentProvider/Edit';
import PaymentProviderList from '../components/PaymentProvider/List';
import PaymentProviderShow from '../components/PaymentProvider/Show';
import ArticleCreate from '../components/Article/Create';
import ArticleEdit from '../components/Article/Edit';
import ArticleList from '../components/Article/List';
import ArticleShow from '../components/Article/Show';
import ArticleTranslationCreate from '../components/ArticleTranslation/Create';
import ArticleTranslationEdit from '../components/ArticleTranslation/Edit';
import ArticleTranslationList from '../components/ArticleTranslation/List';
import ArticleTranslationShow from '../components/ArticleTranslation/Show';
import PaymentList from '../components/Payment/List';
import PaymentShow from '../components/Payment/Show';

const pages = [
  {
    id: 1,
    title: 'Заказы',
    icon: OrderIcon,
    resource: {
      name: 'Order',
      edit: OrderEdit,
      list: OrderList,
      show: OrderShow,
    },
  },
  {
    id: 2,
    title: 'Пользователи',
    icon: SupervisedUserCircleIcon,
    resource: {
      name: 'User',
      create: UserCreate,
      edit: UserEdit,
      list: UserList,
      show: UserShow,
    },
  },
  {
    id: 3,
    title: 'Товары',
    icon: PaletteIcon,
    resource: {
      name: 'Product',
      create: ProductCreate,
      list: ProductList,
      show: ProductShow,
      edit: ProductEdit,
    },
  },
  {
    id: 4,
    title: 'Группы товаров',
    icon: GroupIcon,
    resource: {
      name: 'ProductGroup',
      create: ProductGroupCreate,
      edit: ProductGroupEdit,
      list: ProductGroupList,
      show: ProductGroupShow,
    },
  },
  {
    id: 5,
    title: 'Курсы',
    icon: GolfCourseIcon,
    resource: {
      name: 'Course',
      create: CourseCreate,
      edit: CourseEdit,
      list: CourseList,
      show: CourseShow,
    },
  },
  {
    id: 6,
    title: 'Уроки',
    icon: PlayLessonIcon,
    resource: {
      name: 'Lesson',
      create: LessonCreate,
      edit: LessonEdit,
      list: LessonList,
      show: LessonShow,
    },
  },
  {
    id: 7,
    title: 'Стоимость курса',
    icon: PriceChangeIcon,
    resource: {
      name: 'CoursePrice',
      create: CoursePriceCreate,
      edit: CoursePriceEdit,
      list: CoursePriceList,
      show: CoursePriceShow,
    },
  },
  {
    id: 8,
    title: 'Группы студентов',
    icon: GroupIcon,
    resource: {
      name: 'StudentGroup',
      create: StudentGroupCreate,
      edit: StudentGroupEdit,
      list: StudentGroupList,
      show: StudentGroupShow,
    },
  },
  {
    id: 9,
    title: 'Авторы',
    icon: Face3Icon,
    resource: {
      name: 'Author',
      create: AuthorCreate,
      edit: AuthorEdit,
      list: AuthorList,
      show: AuthorShow,
    },
  },
  {
    id: 10,
    title: 'Отзывы',
    icon: ReviewsIcon,
    resource: {
      name: 'Review',
      edit: ReviewEdit,
      list: ReviewList,
      show: ReviewShow,
    },
  },
  {
    id: 11,
    title: 'Статьи',
    icon: ArticleIcon,
    resource: {
      name: 'Article',
      create: ArticleCreate,
      edit: ArticleEdit,
      list: ArticleList,
      show: ArticleShow,
    },
  },
  {
    id: 12,
    title: 'Переводы статей',
    icon: GTranslateIcon,
    resource: {
      name: 'ArticleTranslation',
      create: ArticleTranslationCreate,
      edit: ArticleTranslationEdit,
      list: ArticleTranslationList,
      show: ArticleTranslationShow,
    },
  },
  {
    id: 13,
    title: 'Файлы',
    icon: FileOpenIcon,
    resource: {
      name: 'Media',
      create: MediaCreate,
      list: MediaList,
      show: MediaShow,
    },
  },
  {
    id: 14,
    title: 'Платежи',
    icon: PaymentIcon,
    resource: {
      name: 'Payment',
      list: PaymentList,
      show: PaymentShow,
    },
  },
  {
    id: 15,
    title: 'Поставщики платежей',
    icon: AccountBalanceIcon,
    resource: {
      name: 'PaymentProvider',
      create: PaymentProviderCreate,
      edit: PaymentProviderEdit,
      list: PaymentProviderList,
      show: PaymentProviderShow,
    },
  },
  {
    id: 16,
    title: 'Расходы',
    icon: SavingsIcon,
    resource: {
      name: 'Spending',
      create: SpendingCreate,
      list: SpendingList,
      show: SpendingShow,
    },
  },
];

export default pages;

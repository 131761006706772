import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

const link = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  const operationName = _.operationName || '';
  const access_token = localStorage.getItem('access_token');
  return {
    headers: {
      ...headers,
      authorization: access_token ? `Bearer ${access_token}` : '',
      'x-apollo-operation-name': operationName,
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, link]),
  cache: new InMemoryCache({
    typePolicies: {
      Order: { keyFields: ['id'] },
    },
  }),
  connectToDevTools: process.env.NODE_ENV !== 'production',
});

export default client;

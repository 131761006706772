import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  BooleanInput,
  ImageField,
  ImageInput,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import CustomSelectInput from '../CustomSelectInput';
import { useWatch } from 'react-hook-form';
import CustomRichTextInput from '../CustomRichTextInput';

const FormComponent = () => {
  const feedBackAvailable = useWatch({
    name: 'feedBackAvailable',
    defaultValue: false,
  });

  const title = useWatch({
    name: 'title',
  });

  const chatGptText = `Создай виртуозное описание видео курса под названием ${title} чтобы покупатель захотел его купить, так-же дополни текст красивыми html тегами`;

  return (
    <Grid container flexDirection="column">
      <Grid item>
        <ImageInput
          accept="image/*"
          multiple={false}
          source="logoMedia"
          label="Logo"
          fullWidth
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput source="title" fullWidth />
      </Grid>

      <Grid item>
        <CustomRichTextInput
          chatGptText={chatGptText}
          source="description"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <BooleanInput
          defaultValue={false}
          source="feedBackAvailable"
          fullWidth
        />
      </Grid>

      {feedBackAvailable && (
        <Grid item xs={12} sm={6}>
          <NumberInput
            label="Feedback Price EUR"
            source="feedBackPrice"
            fullWidth
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <CustomSelectInput
          label="Student Group"
          source="studentGroupId"
          reference="StudentGroup"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CustomSelectInput label="Автор" source="authorId" reference="Author" />
      </Grid>
    </Grid>
  );
};

const CreateUpdateForm = ({ title }) => {
  return (
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <FormComponent />
    </SimpleForm>
  );
};

export default CreateUpdateForm;

import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  BooleanInput,
  ImageField,
  ImageInput,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const FormComponent = () => {
  return (
    <Grid container flexDirection="column">
      <Grid item>
        <ImageInput
          accept="image/*"
          multiple={false}
          source="media"
          label="Media"
          fullWidth
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput source="title" fullWidth />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput source="slug" fullWidth />
      </Grid>

      <Grid item xs={12} sm={6}>
        <NumberInput source="sortingOrder" fullWidth defaultValue={1} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput source="trxUrlTemplate" fullWidth />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput source="websiteUrl" fullWidth />
      </Grid>

      <Grid item xs={12} sm={6}>
        <BooleanInput defaultValue={false} source="isEnabled" fullWidth />
      </Grid>
    </Grid>
  );
};

const CreateUpdateForm = ({ title }) => {
  return (
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <FormComponent />
    </SimpleForm>
  );
};

export default CreateUpdateForm;

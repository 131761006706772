import { gql } from '@apollo/client';
import { PRODUCT_FRAGMENT } from '../fragments/productFragment';

export const UPDATE_PRODUCT_MUTATION = gql`
  ${PRODUCT_FRAGMENT}
  mutation UpdateProduct($id: ID!, $data: UpdateProductInput!) {
    updateProduct(id: $id, data: $data) {
      ...ProductFragment
    }
  }
`;

import React, { useState } from 'react';
import { Link, useRecordContext } from 'react-admin';
import useGeneratePresignedMediaUrl from '../../hooks/useGeneratePresignedMediaUrl';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';

const Component = () => {
  const record = useRecordContext();
  const { loading, url } = useGeneratePresignedMediaUrl(record);

  if (loading) return <div />;

  return (
    <Link target="_blank" to={url}>
      {url}
    </Link>
  );
};

const PrivateMediaSrc = () => {
  const [show, setShow] = useState(false);

  return (
    <Grid container direction="column">
      <Grid item>
        <Button variant="contained" onClick={() => setShow((prev) => !prev)}>
          {show ? 'Hide Private Src' : 'Show Private Src'}
        </Button>
      </Grid>
      {show && <Component />}
    </Grid>
  );
};

export default PrivateMediaSrc;

import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../fragments/userFragment';

export const CREATE_USER_MUTATION = gql`
  ${USER_FRAGMENT}
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...UserFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { ArticleTranslationFragment } from '../fragments/ArticleTranslationFragment';

export const allArticleTranslations = gql`
  ${ArticleTranslationFragment}
  query AllArticles(
    $filter: ArticleTranslationsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allArticleTranslations(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      data {
        ...ArticleTranslationFragment
      }
      total
    }
  }
`;

export const transformMedias = async (data, options) => {
  const result = { ...data };

  if (data?.medias) {
    if (options?.previousData?.medias) {
      const initialIds = options.previousData.medias.map((media) => media.id);
      const newIds = result.medias.map((media) => media.id);
      const deleteMediaIds = initialIds.filter((id) => !newIds.includes(id));

      if (deleteMediaIds.length) result.deleteMediaIds = deleteMediaIds;
    }

    result.medias = await Promise.all(
      data.medias
        .filter((i) => Boolean(i.rawFile))
        .map(async (file) => {
          return file.rawFile;
        })
    );
  }

  return result;
};

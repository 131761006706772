import { gql } from '@apollo/client';
import { OrderFragment } from '../fragments/OrderFragment';

export const updateOrder = gql`
  ${OrderFragment}
  mutation UpdateOrder($id: ID!, $input: UpdateOrderInput!) {
    updateOrder(id: $id, input: $input) {
      ...OrderFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { LESSON_FRAGMENT } from '../fragments/lessonFragment';

export const LESSONS_QUERY = gql`
  ${LESSON_FRAGMENT}
  query Lessons(
    $filter: LessonsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allLessons(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...LessonFragment
      }
      total
    }
  }
`;

import { gql } from '@apollo/client';
import { PaymentProviderFragment } from '../fragments/paymentProviderFragment';

export const PaymentProvider = gql`
  ${PaymentProviderFragment}
  query PaymentProvider($id: ID!) {
    PaymentProvider(id: $id) {
      ...PaymentProviderFragment
    }
  }
`;

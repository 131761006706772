import { gql } from '@apollo/client';
import { ProductGroupFragment } from './productGroupFragment';
import { MediaFragment } from './mediaFragment';
import { AuthorFragment } from './authorFragment';

export const PRODUCT_FRAGMENT = gql`
  ${MediaFragment}
  ${ProductGroupFragment}
  ${AuthorFragment}
  fragment ProductFragment on Product {
    id
    price
    quantity
    title
    description
    userId
    createdAt
    width
    height
    measurementUnit
    productGroupId
    authorId
    author {
      ...AuthorFragment
    }
    productGroup {
      ...ProductGroupFragment
    }
    user {
      id
      firstName
      lastName
    }
    medias {
      ...MediaFragment
    }
  }
`;

import { useQuery } from 'react-query';
import { generatePresignedMediaUrl } from '../graphql/queries/generatePresignedMediaUrl';
import fetchCallback from '../react-query/fetchCallback';

const fetchMediaUrl = async (mediaId) => {
  const data = await fetchCallback({
    mutation: generatePresignedMediaUrl,
    variables: { mediaId },
  });
  return data.generatePresignedMediaUrl;
};

function useGeneratePresignedMediaUrl(media) {
  const { data: mediaUrl, isLoading } = useQuery(
    ['generatePresignedMediaUrl', media.id],
    () => fetchMediaUrl(media.id)
  );

  return {
    url: mediaUrl,
    loading: isLoading,
  };
}

export default useGeneratePresignedMediaUrl;

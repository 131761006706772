import React from 'react';
import { Link, useRecordContext } from 'react-admin';

import ListPage from '../CoursePrice/List';
import Button from '@mui/material/Button';

const CoursePricesTab = () => {
  const record = useRecordContext();

  return (
    <div>
      <Link to="/CoursePrice/create">
        <Button style={{ marginBottom: 16 }} variant="contained">
          Добавить Вариант цены
        </Button>
      </Link>
      <ListPage
        resource="CoursePrice"
        data={record.prices}
        storeKey={false}
        actions={null}
        bulkActionButtons={null}
        pagination={false}
        exporter={false}
        filters={null}
        hasCreate={false}
        hideRows={{
          course: true,
        }}
      />
    </div>
  );
};

export default CoursePricesTab;

import React from 'react';

import {
  Filter,
  List,
  Datagrid,
  TextField,
  TextInput,
  DateInput,
  DateField,
  FunctionField,
  Link,
} from 'react-admin';
import PropTypes from 'prop-types';

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Title" source="title" defaultVal="" />
    <TextInput label="Description" source="description" defaultVal="" />
    <DateInput label="Дата создания" source="createdAt" />
  </Filter>
);

const ListPage = ({ rowClick, ...props }) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={props.filters || <Filters />}
      {...props}
    >
      <Datagrid
        bulkActionButtons={props?.bulkActionButtons}
        data={props?.data}
        rowClick={rowClick}
      >
        <DateField source="createdAt" label="Дата создания" />
        <TextField source="title" label="Заголовок" />
        {!props?.hideRows?.course && (
          <FunctionField
            label="Курс"
            render={(record) =>
              record.course ? (
                <Link
                  onClick={(e) => e.stopPropagation()}
                  to={`/Course/${record.course.id}/show`}
                >
                  {record.course.title}
                </Link>
              ) : null
            }
          />
        )}
      </Datagrid>
    </List>
  );
};

ListPage.propTypes = {
  rowClick: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
};

ListPage.defaultProps = {
  rowClick: 'edit',
};

export default ListPage;

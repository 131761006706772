import { gql } from '@apollo/client';
import { ReviewFragment } from '../fragments/reviewFragment';

export const removeReview = gql`
  ${ReviewFragment}
  mutation RemoveReview($id: ID!) {
    deleteReview(id: $id) {
      ...ReviewFragment
    }
  }
`;

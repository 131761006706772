import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateField,
  SelectInput,
  Link,
  FunctionField,
} from 'react-admin';
import articleTranslationLngChoices from '../../choices/articleTranslationLngChoices';
import CustomSelectInput from '../CustomSelectInput';

const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <TextInput source="id" />,
        <CustomSelectInput
          alwaysOn
          source="articleId"
          reference="Article"
          resettable
        />,
        <SelectInput
          alwaysOn
          source="lng"
          choices={articleTranslationLngChoices}
          fullWidth
          resettable
        />,
        <TextInput source="search" />,
        <TextInput source="title" />,
        <TextInput source="content" />,
      ]}
      {...props}
    >
      <Datagrid rowClick="edit">
        <FunctionField
          label="Article"
          render={(record) =>
            record.article ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/Article/${record.article.id}/show`}
              >{`${record.article.title} ${record.article.title}`}</Link>
            ) : null
          }
        />
        <TextField source="lng" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default ListPage;

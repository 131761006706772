import React from 'react';
import { Box, Typography } from '@mui/material';
import { FileInput, NumberInput, SimpleForm, TextInput } from 'react-admin';
import CustomSelectInput from '../CustomSelectInput';
import CustomFileField from './CustomFileField';
import { useWatch } from 'react-hook-form';
import CustomRichTextInput from '../CustomRichTextInput';

const FormComponent = () => {
  const title = useWatch({
    name: 'title',
  });

  const chatGptText = `Создай виртуозное описание видео урока под названием ${title} чтобы покупатель захотел его купить, так-же дополни текст красивыми html тегами`;
  return (
    <>
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="title" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <CustomSelectInput
            label="Course"
            source="courseId"
            reference="Course"
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput source="sortingOrder" fullWidth />
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <CustomRichTextInput
            chatGptText={chatGptText}
            source="description"
            fullWidth
          />
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <FileInput source="medias" label="Medias" multiple={true}>
            <CustomFileField source="src" title="title" />
          </FileInput>
        </Box>
      </Box>
    </>
  );
};

const CreateUpdateForm = ({ title }) => {
  return (
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <FormComponent />
    </SimpleForm>
  );
};

export default CreateUpdateForm;

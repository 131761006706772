import { gql } from '@apollo/client';
import { ProductGroupFragment } from '../fragments/productGroupFragment';

export const updateProductGroup = gql`
  ${ProductGroupFragment}
  mutation updateProductGroup($id: ID!, $data: UpdateProductGroupInput!) {
    updateProductGroup(id: $id, data: $data) {
      ...ProductGroupFragment
    }
  }
`;

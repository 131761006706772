import { gql } from '@apollo/client';
import { OrderFragment } from '../fragments/OrderFragment';

export const OrderQuery = gql`
  ${OrderFragment}
  query Order($id: ID!) {
    Order(id: $id) {
      ...OrderFragment
    }
  }
`;

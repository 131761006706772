import { gql } from '@apollo/client';
import { PaymentProviderFragment } from '../fragments/paymentProviderFragment';

export const createPaymentProvider = gql`
  ${PaymentProviderFragment}
  mutation CreatePaymentProvider($data: CreatePaymentProviderInput!) {
    createPaymentProvider(data: $data) {
      ...PaymentProviderFragment
    }
  }
`;

import React from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  NumberField,
  useRecordContext,
} from 'react-admin';

const OrderProductTab = () => {
  const record = useRecordContext();
  return (
    <ArrayField source="orderProducts" record={record}>
      <Datagrid bulkActionButtons={null}>
        <FunctionField
          label="Товар"
          render={(record) =>
            record.product ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/Product/${record.product.id}/show`}
              >
                {record.product.title}
              </Link>
            ) : null
          }
        />
        <NumberField label="Количество" source="productCount" />
        <NumberField label="Цена" source="productPrice" />
        <DateField label="Дата создания" source="createdAt" />
      </Datagrid>
    </ArrayField>
  );
};

export default OrderProductTab;

import React from 'react';
import buildGraphQLProvider from 'ra-data-graphql-simple';
import { Admin, Resource } from 'react-admin';
import { GoogleOAuthProvider } from '@react-oauth/google';

import apolloClient from './utils/apolloClient';
import { MyLayout } from './components/MyLayout';
import { authProvider } from './utils/authProvider';
import MyLoginPage from './components/MyLoginPage';
import { buildQuery } from './utils/buildQuery';
import { i18nProvider } from './i18nProvider';
import pages from './utils/pages';

const App = () => {
  const [dataProvider, setDataProvider] = React.useState(null);
  React.useEffect(() => {
    buildGraphQLProvider({ client: apolloClient, buildQuery }).then(
      (graphQlDataProvider) => setDataProvider(() => graphQlDataProvider)
    );
  }, []);

  if (!dataProvider) {
    return <div />;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
      <Admin
        locale="uk"
        i18nProvider={i18nProvider}
        loginPage={MyLoginPage}
        authProvider={authProvider}
        layout={MyLayout}
        dataProvider={dataProvider}
        requireAuth
      >
        {pages.map((page) => (
          <Resource
            key={page.id}
            hasCreate={Boolean(page.resource.create)}
            hasShow={Boolean(page.resource.show)}
            hasEdit={Boolean(page.resource.edit)}
            {...page.resource}
          />
        ))}
      </Admin>
    </GoogleOAuthProvider>
  );
};

export default App;

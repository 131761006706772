import { gql } from '@apollo/client';
import { OrderFragment } from '../fragments/OrderFragment';

export const OrdersQuery = gql`
  ${OrderFragment}
  query Orders(
    $filter: OrdersFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allOrders(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...OrderFragment
      }
      total
    }
  }
`;

import React from 'react';
import { useRecordContext } from 'react-admin';
import { Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled('div')(() => ({
  display: 'inline-block',
}));

const PaymentStatusField = ({ source, value: passedValue }) => {
  const record = useRecordContext();
  const value = passedValue || record[source];

  const getSeverity = () => {
    switch (value) {
      case 'created':
        return 'info';
      case 'processing':
        return 'info';
      case 'requires_action':
        return 'warning';
      case 'requires_capture':
        return 'warning';
      case 'requires_confirmation':
        return 'warning';
      case 'requires_payment_method':
        return 'warning';
      case 'canceled':
        return 'error';
      case 'succeeded':
        return 'success';
      default:
        return undefined;
    }
  };

  return (
    <Root>
      <Alert severity={getSeverity()}>{value}</Alert>
    </Root>
  );
};

export default PaymentStatusField;

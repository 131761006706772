import { gql } from '@apollo/client';
import { PaymentProviderFragment } from '../fragments/paymentProviderFragment';

export const updatePaymentProvider = gql`
  ${PaymentProviderFragment}
  mutation UpdatePaymentProvider($id: ID!, $data: UpdatePaymentProviderInput!) {
    updatePaymentProvider(id: $id, data: $data) {
      ...PaymentProviderFragment
    }
  }
`;

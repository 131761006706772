import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateInput,
  DateField,
  FunctionField,
  Link,
} from 'react-admin';

const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <TextInput source="title" name="title" />,
        <DateInput source="createdAt" name="createdAt" />,
      ]}
      {...props}
    >
      <Datagrid rowClick="edit">
        <DateField source="createdAt" label="Дата" />
        <TextField source="title" label="Назва" />
        <FunctionField
          label="Користувач"
          render={(record) =>
            record.user ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/User/${record.user.id}/show`}
              >{`${record.user.firstName} ${record.user.lastName}`}</Link>
            ) : null
          }
        />
      </Datagrid>
    </List>
  );
};

export default ListPage;

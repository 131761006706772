import React from 'react';

import {
  Filter,
  List,
  Datagrid,
  TextField,
  TextInput,
  DateInput,
  DateField,
  BooleanField,
  ImageField,
  BooleanInput,
  NumberField,
} from 'react-admin';

const Filters = (props) => (
  <Filter {...props}>
    <TextInput source="search" defaultVal="" />
    <TextInput source="id" defaultVal="" />
    <TextInput source="title" defaultVal="" />
    <TextInput source="slug" defaultVal="" />
    <TextInput source="trxUrlTemplate" defaultVal="" />
    <BooleanInput source="isEnabled" defaultValue={false} />
    <DateInput source="createdAt" />
    <DateInput source="deletedAt" />
  </Filter>
);

const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={props.filters || <Filters />}
      {...props}
    >
      <Datagrid
        bulkActionButtons={props?.bulkActionButtons}
        data={props?.data}
        rowClick={props?.rowClick || 'edit'}
      >
        <ImageField source="media.src" label="Media" />
        <DateField source="createdAt" />
        <TextField source="title" />
        <TextField source="slug" />
        <BooleanField source="isEnabled" />
        <NumberField source="sortingOrder" />
      </Datagrid>
    </List>
  );
};

export default ListPage;

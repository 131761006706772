import React from 'react';
import { Alert, Grid, Typography } from '@mui/material';
import {
  FileField,
  FileInput,
  Link,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';
import CustomRichTextInput from '../CustomRichTextInput';
import { useFormContext, useWatch } from 'react-hook-form';
import articleStatusChoices from '../../choices/articleStatusChoices';
import Button from '@mui/material/Button';
import useGenerateChatGptText from '../../hooks/useGenerateChatGptText';
import stringToSlug from '../../utils/stringToSlug';

const Component = () => {
  const notify = useNotify();
  const generateChatGptText = useGenerateChatGptText();
  const form = useFormContext();

  const title = useWatch({
    name: 'title',
  });

  const slug = useWatch({
    name: 'slug',
  });

  const removeMedias = useWatch({
    name: 'removeMedias',
    defaultValue: [],
  });

  const chatGptText = `Создай виртуозное содержание статьи под названием ${title} так-же дополни текст html тегами для красоты`;

  const handleGenerateSlug = async () => {
    generateChatGptText.cb(
      {
        text: `generate URL-friendly version of the title "${title}", used for creating readable URLs (like "example.com/news/my-great-article" where "my-great-article" is the slug)`,
      },
      {
        onSuccess: (value) => {
          form.setValue('slug', stringToSlug(value), {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        },
        onError: (err) => {
          notify(err.message, { type: 'error' });
        },
      }
    );
  };

  const handleRemoveMedias = (media) => {
    if (media.id && !removeMedias.includes(media.id)) {
      form.setValue('removeMedias', [...removeMedias, media.id], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  };

  return (
    <>
      <FileInput multiple={false} source="media" label="Media">
        <FileField source="src" title="title" />
      </FileInput>

      <TextInput source="title" fullWidth />

      <Grid container direction="column" gap="1em">
        <TextInput source="slug" fullWidth />

        <Alert severity="info">
          <p>URL статьи:</p>
          <Link
            to={`${process.env.REACT_APP_WEBSITE_URL}/articles/${slug || ''}`}
            target="_blank"
          >{`${process.env.REACT_APP_WEBSITE_URL}/articles/${
            slug || ''
          }`}</Link>
        </Alert>

        <div>
          <Button
            disabled={generateChatGptText.loading || !title}
            variant="contained"
            size="small"
            onClick={handleGenerateSlug}
          >
            Generate slug
          </Button>
        </div>
      </Grid>

      <CustomRichTextInput
        chatGptText={chatGptText}
        source="content"
        fullWidth
      />

      <div>
        <FileInput
          multiple
          source="medias"
          label="Medias"
          onRemove={handleRemoveMedias}
        >
          <FileField source="src" title="title" />
        </FileInput>

        <Alert severity="info">
          Медиафайлы будут показаны после текста статьи.
        </Alert>
      </div>

      <TextInput source="metaDescription" fullWidth />

      <TextInput source="metaKeywords" fullWidth />

      <SelectInput source="status" choices={articleStatusChoices} fullWidth />
    </>
  );
};

const CreateUpdateForm = ({ title }) => {
  return (
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Component />
    </SimpleForm>
  );
};

export default CreateUpdateForm;

import { gql } from '@apollo/client';
import { MediaFragment } from '../fragments/mediaFragment';

export const allMedias = gql`
  ${MediaFragment}
  query Medias(
    $filter: MediasFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allMedia(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...MediaFragment
      }
      total
    }
  }
`;

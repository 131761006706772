import { gql } from '@apollo/client';
import { AuthorFragment } from '../fragments/authorFragment';

export const AllAuthors = gql`
  ${AuthorFragment}
  query AllAuthors(
    $filter: AuthorsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allAuthors(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...AuthorFragment
      }
      total
    }
  }
`;

import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateField,
  SelectInput,
  ImageField,
} from 'react-admin';
import articleStatusChoices from '../../choices/articleStatusChoices';
import ArticleStatusField from './ArticleStatusField';

const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <TextInput source="id" />,
        <TextInput alwaysOn source="search" />,
        <TextInput source="title" />,
        <TextInput source="content" />,
        <TextInput source="metaDescription" />,
        <TextInput source="metaKeywords" />,
        <TextInput source="slug" />,
        <SelectInput
          source="status"
          choices={articleStatusChoices}
          fullWidth
        />,
      ]}
      {...props}
    >
      <Datagrid rowClick="edit">
        <ImageField source="media.src" />
        <DateField source="createdAt" />
        <TextField source="title" />
        <ArticleStatusField source="status" />
      </Datagrid>
    </List>
  );
};

export default ListPage;

import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  FunctionField,
  Link,
} from 'react-admin';

const SpendingShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" label="Дата створення" />
      <DateField source="spendingDate" label="Дата витрати" />
      <TextField source="description" label="Опис" />
      <NumberField source="amount" label="Сума" />
      <TextField source="currency" label="Валюта" />
      <FunctionField
        label="Користувач"
        render={(record) =>
          record.user ? (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/User/${record.user.id}/show`}
            >{`${record.user.firstName} ${record.user.lastName}`}</Link>
          ) : null
        }
      />
    </SimpleShowLayout>
  </Show>
);

export default SpendingShow;

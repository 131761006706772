import React, { Suspense, lazy, useMemo, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useRecordSelection } from 'react-admin';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogListComponent({
  open,
  onClose,
  reference,
  label,
  defaultIds = [],
  onSelect,
}) {
  const [selectedIds, { clearSelection, select }] =
    useRecordSelection(reference);

  const ListComponent = useMemo(
    () => lazy(() => import(`../${reference}/List`)),
    [reference]
  );

  const handleClose = () => {
    onClose();
    clearSelection();
  };

  const handleAddSelected = () => {
    onSelect(selectedIds);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      select(defaultIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        {open && (
          <Suspense fallback={<div>Loading...</div>}>
            <ListComponent
              // storeKey={`CustomSelectArrayInput.Dialog.${reference}`}
              storeKey={false}
              exporter={false}
              hasCreate={false}
              bulkActionButtons={
                <Button variant="contained" onClick={handleAddSelected}>
                  Добавить выбранное
                </Button>
              }
              rowClick={false}
              resource={reference}
            />
          </Suspense>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

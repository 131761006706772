import { gql } from '@apollo/client';
import { ArticleTranslationFragment } from '../fragments/ArticleTranslationFragment';

export const ArticleTranslation = gql`
  ${ArticleTranslationFragment}
  query ArticleTranslation($id: ID!) {
    ArticleTranslation(id: $id) {
      ...ArticleTranslationFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { USER_FRAGMENT } from './userFragment';
import { PRODUCT_FRAGMENT } from './productFragment';
import { CourseFragment } from './courseFragment';
import { PaymentFragment } from './paymentFragment';
import { OrderCourseFragment } from './orderCourseFragment';

export const OrderFragment = gql`
  ${PRODUCT_FRAGMENT}
  ${USER_FRAGMENT}
  ${PaymentFragment}
  ${CourseFragment}
  ${OrderCourseFragment}
  fragment OrderFragment on Order {
    id
    status
    userId
    createdAt
    totalPrice
    deliveryTrackingUrl
    createdAt
    paymentId
    orderProducts {
      id
      orderId
      productId
      productCount
      createdAt
      productPrice
      product {
        ...ProductFragment
      }
    }
    orderCourses {
      ...OrderCourseFragment
    }
    payment {
      ...PaymentFragment
    }
    user {
      ...UserFragment
    }
  }
`;

import React from 'react';
import { Link, useRecordContext } from 'react-admin';
import { useQuery } from 'react-query';
import { userCoursesByUserId } from '../../graphql/queries/userCoursesByUserId';
import AddUserCourseButton from './AddUserCourseButton';
import fetchCallback from '../../react-query/fetchCallback';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Checkbox, Grid } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

const CoursesTab = () => {
  const record = useRecordContext();

  const cb = async () => {
    const data = await fetchCallback({
      mutation: userCoursesByUserId,
      variables: { userId: record.id },
    });
    return data.userCoursesByUserId;
  };

  const { data, isLoading, refetch } = useQuery(
    ['userBuyedCourses', record.id],
    cb
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <AddUserCourseButton onSuccess={refetch} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>createdAt</TableCell>
              <TableCell align="right">Course</TableCell>
              <TableCell align="right">accessEndsAt</TableCell>
              <TableCell align="right">feedBackAvailable</TableCell>
              <TableCell align="right">Order</TableCell>
              <TableCell align="right">Payments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {format(parseISO(row.createdAt), 'PPPP', { locale: ru })}
                </TableCell>
                <TableCell align="right">
                  <Link to={`/Course/${row.orderCourse.courseId}/show`}>
                    {row.orderCourse.course.title}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  {format(parseISO(row.accessEndsAt), 'PPPP', { locale: ru })}
                </TableCell>
                <TableCell align="right">
                  <Checkbox disabled checked={row.feedBackAvailable} />
                </TableCell>
                <TableCell align="right">
                  <Link to={`/Order/${row.orderCourse.orderId}/show`}>
                    {row.orderCourse.orderId}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <Grid container direction="column" gap="1em">
                    {row.userCoursePayments.map((userCoursePayment) => (
                      <Grid item key={userCoursePayment.id}>
                        <Link
                          to={`/Payment/${userCoursePayment.payment.id}/show`}
                        >
                          {userCoursePayment.payment.paymentDetails.description}
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CoursesTab;

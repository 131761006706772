import { gql } from '@apollo/client';
import { PaymentFragment } from '../fragments/paymentFragment';

export const allPayments = gql`
  ${PaymentFragment}
  query PaymentFragment(
    $filter: PaymentsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allPayments(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...PaymentFragment
      }
      total
    }
  }
`;

import { Menu } from 'react-admin';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import React from 'react';
import MenuItem from './MenuItem';
import pages from '../utils/pages';

export const MyMenu = () => {
  return (
    <Menu>
      {pages.map((page) => {
        const Icon = page.icon;
        return (
          <MenuItem
            key={page.id}
            to={`/${page.resource.name}`}
            primaryText={page.title}
            leftIcon={<Icon />}
          />
        );
      })}
      <Menu.Item
        target="_blank"
        to="https://analytics.google.com"
        primaryText="Google Аналитика"
        leftIcon={<AnalyticsIcon />}
      />
      <Menu.Item
        target="_blank"
        to={process.env.REACT_APP_WEBSITE_URL}
        primaryText="Веб-сайт"
        leftIcon={<OpenInBrowserIcon />}
      />
    </Menu>
  );
};

import { gql } from '@apollo/client';

export const AuthorFragment = gql`
  fragment AuthorFragment on Author {
    id
    name
    title
    createdAt
  }
`;

import React from 'react';
import { Link, useRecordContext } from 'react-admin';

import LessonList from '../Lesson/List';
import Button from '@mui/material/Button';

const LessonsTab = () => {
  const record = useRecordContext();

  return (
    <div>
      <Link to="/Lesson/create">
        <Button style={{ marginBottom: 16 }} variant="contained">
          Добавить урок
        </Button>
      </Link>
      <LessonList
        resource="Lesson"
        data={record.lessons}
        storeKey={false}
        actions={null}
        bulkActionButtons={null}
        pagination={false}
        exporter={false}
        filters={null}
        hasCreate={false}
        hideRows={{ course: true }}
      />
    </div>
  );
};

export default LessonsTab;

import * as React from 'react';
import { Edit } from 'react-admin';

import CreateUpdateForm from './CreateUpdateForm';
import { transformMedias } from '../../utils/transformMedias';

const LessonEdit = () => {
  return (
    <Edit mutationMode="pessimistic" transform={transformMedias}>
      <CreateUpdateForm title="Редактировать урок" />
    </Edit>
  );
};

export default LessonEdit;

import { gql } from '@apollo/client';
import { ProductGroupFragment } from '../fragments/productGroupFragment';

export const createProductGroup = gql`
  ${ProductGroupFragment}
  mutation CreateProductGroup($data: CreateProductGroupInput!) {
    createProductGroup(data: $data) {
      ...ProductGroupFragment
    }
  }
`;

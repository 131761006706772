const paymentStatusChoices = [
  { id: 'created', name: 'Created' },
  { id: 'processing', name: 'Processing' },
  { id: 'requires_action', name: 'Requires Action' },
  { id: 'requires_capture', name: 'Requires Capture' },
  { id: 'requires_confirmation', name: 'Requires Confirmation' },
  { id: 'requires_payment_method', name: 'Requires Payment Method' },
  { id: 'canceled', name: 'Canceled' },
  { id: 'succeeded', name: 'Succeeded' },
];

export default paymentStatusChoices;

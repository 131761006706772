import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateInput,
  DateField,
  NumberInput,
  NumberField,
  FunctionField,
  Link,
  SelectInput,
} from 'react-admin';
import currencyChoices from '../../utils/currencyChoices';

export const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <NumberInput source="amount" name="amount" />,
        <SelectInput
          fullWidth
          source="currency"
          name="currency"
          choices={currencyChoices}
        />,
        <TextInput source="description" name="description" />,
        <DateInput source="createdAt" name="createdAt" />,
      ]}
      {...props}
    >
      <Datagrid rowClick="show">
        <DateField source="createdAt" label="Дата створення" />
        <DateField source="spendingDate" label="Дата витрати" />
        <TextField source="description" label="Опис" />
        <NumberField source="amount" label="Сума" />
        <TextField source="currency" label="Валюта" />
        <FunctionField
          label="Користувач"
          render={(record) =>
            record.user ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/User/${record.user.id}/show`}
              >{`${record.user.firstName} ${record.user.lastName}`}</Link>
            ) : null
          }
        />
      </Datagrid>
    </List>
  );
};

export default ListPage;

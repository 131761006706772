import { gql } from '@apollo/client';
import { MediaFragment } from '../fragments/mediaFragment';

export const createMedia = gql`
  ${MediaFragment}
  mutation CreateMedia($data: CreateMediaInput!) {
    createMedia(data: $data) {
      ...MediaFragment
    }
  }
`;

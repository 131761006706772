import * as React from 'react';
import { Edit } from 'react-admin';

import CreateUpdateForm from './CreateUpdateForm';

const EditPage = () => (
  <Edit mutationMode="pessimistic">
    <CreateUpdateForm title="Edit Article Translation" />
  </Edit>
);

export default EditPage;

import * as React from 'react';
import { Create, SimpleForm, TextInput, PasswordInput } from 'react-admin';
import { Box, Typography } from '@mui/material';

const UserCreate = () => (
  <Create mutationMode="pessimistic">
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Новий користувач
      </Typography>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="email" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <PasswordInput source="password" fullWidth />
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="firstName" fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="lastName" fullWidth />
        </Box>
      </Box>
    </SimpleForm>
  </Create>
);

export default UserCreate;

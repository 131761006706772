import { gql } from '@apollo/client';
import { USER_FRAGMENT } from './userFragment';
import { CourseFragment } from './courseFragment';
import { MediaFragment } from './mediaFragment';

export const LESSON_FRAGMENT = gql`
  ${USER_FRAGMENT}
  ${MediaFragment}
  ${CourseFragment}
  fragment LessonFragment on Lesson {
    createdAt
    description
    id
    title
    name
    userId
    courseId
    sortingOrder
    course {
      ...CourseFragment
    }
    user {
      ...UserFragment
    }
    medias {
      ...MediaFragment
    }
  }
`;

import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateInput,
  DateField,
  NumberInput,
  NumberField,
  FunctionField,
  Link,
  ImageField,
} from 'react-admin';
import CustomSelectInput from '../CustomSelectInput';

export const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <TextInput source="title" name="title" />,
        <TextInput source="description" name="description" />,
        <NumberInput source="price" name="price" />,
        <NumberInput source="quantity" name="quantity" />,
        <DateInput source="createdAt" name="createdAt" />,
        <CustomSelectInput
          label="Product Group"
          source="productGroupId"
          reference="ProductGroup"
        />,
      ]}
      {...props}
    >
      <Datagrid rowClick="edit">
        <ImageField source="medias[0].src" label="Фото" />
        <DateField source="createdAt" label="Дата" />
        <FunctionField
          label="Product Group"
          render={(record) =>
            record.productGroup ? record.productGroup.title : ''
          }
        />
        <TextField source="title" label="Назва" />
        <NumberField source="price" label="Ціна EUR" />
        <NumberField source="quantity" label="Кількість" />
        <FunctionField
          label="Пользователь"
          render={(record) =>
            record.user ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/User/${record.user.id}/show`}
              >{`${record.user.firstName} ${record.user.lastName}`}</Link>
            ) : null
          }
        />
        <FunctionField
          label="Автор"
          render={(record) =>
            record.author ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/Author/${record.author.id}/show`}
              >{`${record.author.name}`}</Link>
            ) : null
          }
        />
      </Datagrid>
    </List>
  );
};

export default ListPage;

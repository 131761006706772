import { gql } from '@apollo/client';
import { ArticleFragment } from '../fragments/ArticleFragment';

export const allArticles = gql`
  ${ArticleFragment}
  query AllArticles(
    $filter: ArticlesFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allArticles(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...ArticleFragment
      }
      total
    }
  }
`;

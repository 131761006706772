import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../fragments/userFragment';

export const UPDATE_USER_MUTATION = gql`
  ${USER_FRAGMENT}
  mutation UpdateUser($id: ID!, $data: UpdateUserInput!) {
    updateUser(id: $id, data: $data) {
      ...UserFragment
    }
  }
`;

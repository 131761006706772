import { gql } from '@apollo/client';
import { CoursePriceFragment } from '../fragments/coursePriceFragment';

export const createCoursePrice = gql`
  ${CoursePriceFragment}
  mutation CreateCoursePrice($data: CreateCoursePriceInput!) {
    createCoursePrice(data: $data) {
      ...CoursePriceFragment
    }
  }
`;

import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Link,
  FunctionField,
  BooleanField,
  NumberField,
  RichTextField,
} from 'react-admin';

const ShowPage = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" label="Дата создания" />
      <BooleanField source="approved" />
      <NumberField source="rating" />
      <FunctionField
        label="Entity"
        render={(record) =>
          record.entityId ? (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/${record.entityName}/${record.entityId}/show`}
            >{`${record.entityId}`}</Link>
          ) : null
        }
      />
      <FunctionField
        label="Користувач"
        render={(record) =>
          record.user ? (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/User/${record.user.id}/show`}
            >{`${record.user.firstName} ${record.user.lastName}`}</Link>
          ) : null
        }
      />
      <RichTextField source="message" />
    </SimpleShowLayout>
  </Show>
);

export default ShowPage;

import React from 'react';
import { useRecordContext } from 'react-admin';
import useGeneratePresignedMediaUrl from '../../hooks/useGeneratePresignedMediaUrl';

const CustomFileField = ({ source, title }) => {
  const record = useRecordContext();

  const fileName = record[title]; // this is the filename

  const { url } = useGeneratePresignedMediaUrl(record);

  const fileSrc = url || record[source];

  return (
    <a href={fileSrc} target="_blank" rel="noopener noreferrer">
      {fileName}
    </a>
  );
};

export default CustomFileField;

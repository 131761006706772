import { gql } from '@apollo/client';

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      firstName
      lastName
      email
      role
      createdAt
    }
  }
`;

import React from 'react';
import {
  Show,
  TabbedShowLayout,
  TextField,
  DateField,
  EmailField,
  FunctionField,
  Link,
} from 'react-admin';
import CoursesTab from './CoursesTab';

const UserShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Пользователь">
        <TextField source="id" />
        <DateField source="createdAt" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <TextField source="role" />
        <TextField source="address" />
        <TextField source="city" />
        <TextField source="country" />
        <TextField source="phone" />
        <TextField source="postalCode" />
        <TextField source="state" />
        <FunctionField
          label="Payment Provider"
          render={(record) =>
            record.paymentProvider ? (
              <Link to={`/PaymentProvider/${record.paymentProvider.id}`}>
                {record.paymentProvider.title}
              </Link>
            ) : null
          }
        />
        <FunctionField
          label="Stripe Customer"
          render={(record) =>
            record.stripeCustomerId ? (
              <a
                rel="noreferrer noopener"
                target="_blank"
                href={`${process.env.REACT_APP_STRIPE_DASHBOARD_URL}/customers/${record.stripeCustomerId}`}
              >
                {record.stripeCustomerId}
              </a>
            ) : null
          }
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Купленные курсы">
        <CoursesTab />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export default UserShow;

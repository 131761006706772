import { gql } from '@apollo/client';
import { PaymentProviderFragment } from './paymentProviderFragment';

export const USER_FRAGMENT = gql`
  ${PaymentProviderFragment}
  fragment UserFragment on User {
    id
    address
    city
    country
    createdAt
    email
    firstName
    lastName
    phone
    postalCode
    role
    state
    stripeCustomerId
    paymentProviderId
    paymentProvider {
      ...PaymentProviderFragment
    }
  }
`;

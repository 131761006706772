import { gql } from '@apollo/client';
import { STUDENT_GROUP_FRAGMENT } from '../fragments/studentGroupFragment';

export const CREATE_STUDENT_GROUP_MUTATION = gql`
  ${STUDENT_GROUP_FRAGMENT}
  mutation CreateStudentGroup($data: CreateStudentGroupInput!) {
    createStudentGroup(data: $data) {
      ...StudentGroupFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { AuthorFragment } from '../fragments/authorFragment';

export const CreateAuthor = gql`
  ${AuthorFragment}
  mutation CreateAuthor($data: CreateAuthorInput!) {
    createAuthor(data: $data) {
      ...AuthorFragment
    }
  }
`;

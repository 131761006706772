import * as React from 'react';
import { Edit } from 'react-admin';

import CreateUpdateForm from './CreateUpdateForm';

const StudentGroupEdit = () => (
  <Edit mutationMode="pessimistic">
    <CreateUpdateForm title="Редагувати групу студентів" />
  </Edit>
);

export default StudentGroupEdit;

import * as React from 'react';
import { Edit } from 'react-admin';

import CreateUpdateForm from './CreateUpdateForm';
import { transform } from './transform';

const EditPage = () => {
  return (
    <Edit mutationMode="pessimistic" transform={transform}>
      <CreateUpdateForm title="Edit Payment Provider" />
    </Edit>
  );
};

export default EditPage;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { SimpleForm, TextInput } from 'react-admin';

const CreateUpdateForm = ({ title }) => {
  return (
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="title" fullWidth />
        </Box>
      </Box>
    </SimpleForm>
  );
};

export default CreateUpdateForm;

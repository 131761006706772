export const transform = async (data) => {
  if (data?.media) {
    data.media = data.media.rawFile;
  } else data.deleteMedia = true;

  if (data?.medias) {
    data.medias = data.medias.filter((i) => i.rawFile).map((i) => i.rawFile);
  }

  return data;
};

import React from 'react';
import {
  Show,
  TextField,
  DateField,
  RichTextField,
  BooleanField,
  TabbedShowLayout,
  ImageField,
  NumberField,
  FunctionField,
  Link,
} from 'react-admin';

const ShowPage = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Payment Provider">
        <ImageField source="media.src" label="Media" />
        <TextField source="mediaId" />
        <TextField source="id" />
        <TextField source="title" />
        <RichTextField source="slug" />
        <NumberField source="sortingOrder" />
        <TextField source="trxUrlTemplate" />
        <BooleanField source="isEnabled" />
        <DateField source="createdAt" />
        <FunctionField
          label="websiteUrl"
          render={(record) =>
            record.websiteUrl ? (
              <Link target="_blank" to={record.websiteUrl}>
                {record.websiteUrl}
              </Link>
            ) : null
          }
        />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export default ShowPage;

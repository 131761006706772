import React from 'react';

import {
  List,
  Datagrid,
  TextInput,
  DateInput,
  DateField,
  BooleanField,
  NumberField,
  FunctionField,
  Link,
  BooleanInput,
  NumberInput,
  RichTextField,
} from 'react-admin';

const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <BooleanInput source="approved" />,
        <TextInput source="id" />,
        <TextInput source="entityName" />,
        <TextInput source="message" />,
        <NumberInput source="rating" />,
        <DateInput source="createdAt" />,
        <DateInput source="deletedAt" />,
        <TextInput source="userId" />,
      ]}
      {...props}
    >
      <Datagrid rowClick="edit">
        <DateField source="createdAt" label="Дата создания" />
        <FunctionField
          label="Користувач"
          render={(record) =>
            record.user ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/User/${record.user.id}/show`}
              >{`${record.user.firstName} ${record.user.lastName}`}</Link>
            ) : null
          }
        />
        <FunctionField
          label="Entity"
          render={(record) =>
            record.entityId ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/${record.entityName}/${record.entityId}/show`}
              >{`${record.entityName} ${record.entityId}`}</Link>
            ) : null
          }
        />
        <RichTextField source="message" />
        <NumberField source="rating" />
        <BooleanField source="approved" />
      </Datagrid>
    </List>
  );
};

export default ListPage;

import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  FunctionField,
  Link,
  ImageField,
  RichTextField,
} from 'react-admin';

const ProductShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" label="Дата" />
      <TextField source="title" label="Назва" />
      <RichTextField source="description" label="Опис" />
      <NumberField source="price" label="Ціна EUR" />
      <NumberField source="quantity" label="Кількість" />

      <FunctionField
        label="Пользователь"
        render={(record) =>
          record.user ? (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/User/${record.user.id}/show`}
            >{`${record.user.firstName} ${record.user.lastName}`}</Link>
          ) : null
        }
      />
      <FunctionField
        label="Автор"
        render={(record) =>
          record.author ? (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/Author/${record.author.id}/show`}
            >{`${record.author.name}`}</Link>
          ) : null
        }
      />

      <FunctionField
        label="Розміри"
        render={(record) =>
          `${record.width} x ${record.height} ${record.measurementUnit} (Ширина x Висота)`
        }
      />

      <ImageField source="medias" src="src" label="Фото" />
    </SimpleShowLayout>
  </Show>
);

export default ProductShow;

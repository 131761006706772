import { gql } from '@apollo/client';
import { PaymentProviderFragment } from '../fragments/paymentProviderFragment';

export const paymentProviders = gql`
  ${PaymentProviderFragment}
  query PaymentProviders(
    $filter: PaymentProvidersFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allPaymentProviders(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...PaymentProviderFragment
      }
      total
    }
  }
`;

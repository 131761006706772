import { gql } from '@apollo/client';
import { MediaFragment } from './mediaFragment';

export const ArticleFragment = gql`
  ${MediaFragment}
  fragment ArticleFragment on Article {
    content
    createdAt
    id
    metaDescription
    metaKeywords
    slug
    status
    title
    updatedAt
    mediaId
    media {
      ...MediaFragment
    }
    medias {
      ...MediaFragment
    }
  }
`;

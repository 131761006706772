import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  Link,
  FunctionField,
} from 'react-admin';

const ShowPage = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <NumberField source="days" />
      <NumberField label="Price EUR" source="price" />
      <DateField source="createdAt" />
      <FunctionField
        label="Course"
        render={(record) =>
          record.course ? (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/Course/${record.course.id}/show`}
            >
              {record.course.title}
            </Link>
          ) : null
        }
      />
    </SimpleShowLayout>
  </Show>
);

export default ShowPage;

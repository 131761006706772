import { gql } from '@apollo/client';

export const MediaFragment = gql`
  fragment MediaFragment on Media {
    id
    createdAt
    mimeType
    src
    title
    acl
  }
`;

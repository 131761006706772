import { gql } from '@apollo/client';
import { PaymentProviderFragment } from './paymentProviderFragment';

export const PaymentDetailsFragment = gql`
  ${PaymentProviderFragment}
  fragment PaymentDetailsFragment on PaymentDetails {
    createdAt
    description
    id
    metadata
    paymentId
    paymentProviderId
    paymentProviderTrxId
    paymentProviderTrxUrl
    updatedAt
    paymentProvider {
      ...PaymentProviderFragment
    }
  }
`;

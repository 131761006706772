import { gql } from '@apollo/client';
import { ArticleFragment } from '../fragments/ArticleFragment';

export const createArticle = gql`
  ${ArticleFragment}
  mutation CreateArticle($data: CreateArticleInput!) {
    createArticle(data: $data) {
      ...ArticleFragment
    }
  }
`;

import * as React from 'react';
import { Create } from 'react-admin';

import CreateUpdateForm from './CreateUpdateForm';
import { transform } from './transform';

const CreatePage = () => {
  return (
    <Create mutationMode="pessimistic" transform={transform}>
      <CreateUpdateForm title="Create Payment Provider" />
    </Create>
  );
};

export default CreatePage;

import { gql } from '@apollo/client';
import { PRODUCT_FRAGMENT } from '../fragments/productFragment';

export const CREATE_PRODUCT_MUTATION = gql`
  ${PRODUCT_FRAGMENT}
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      ...ProductFragment
    }
  }
`;

import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  EmailField,
  TextInput,
  SelectInput,
  DateInput,
  DateField,
} from 'react-admin';

export const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <TextInput source="firstName" name="firstName" />,
        <TextInput source="lastName" name="lastName" />,
        <TextInput source="email" name="email" />,
        <SelectInput
          source="role"
          name="role"
          choices={[
            { id: 'user', name: 'User' },
            { id: 'admin', name: 'Admin' },
          ]}
        />,
        <DateInput source="createdAt" name="createdAt" />,
      ]}
      {...props}
    >
      <Datagrid rowClick="edit">
        <DateField source="createdAt" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <TextField source="role" />
      </Datagrid>
    </List>
  );
};

export default ListPage;

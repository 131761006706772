import ReactPlayer from 'react-player';
import React, { Suspense } from 'react';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: '56.25%',
}));

const StyledReactPlayer = styled(ReactPlayer)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
}));

const VideoPlayer = ({ url }) => {
  return (
    <Suspense fallback={<div />}>
      <Root>
        <StyledReactPlayer width="100%" height="100%" controls url={url} />
      </Root>
    </Suspense>
  );
};

export default VideoPlayer;

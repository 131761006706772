import { gql } from '@apollo/client';
import { LESSON_FRAGMENT } from '../fragments/lessonFragment';

export const CREATE_LESSON_MUTATION = gql`
  ${LESSON_FRAGMENT}
  mutation CreateLesson($data: CreateLessonInput!) {
    createLesson(data: $data) {
      ...LessonFragment
    }
  }
`;

import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  DateTimeInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import currencyChoices from '../../utils/currencyChoices';

const SpendingCreate = () => (
  <Create>
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Додати витрату
      </Typography>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="description" fullWidth />
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput source="amount" fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <SelectInput
            fullWidth
            source="currency"
            name="currency"
            choices={currencyChoices}
          />
        </Box>
      </Box>

      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <DateTimeInput source="spendingDate" fullWidth />
      </Box>
    </SimpleForm>
  </Create>
);

export default SpendingCreate;

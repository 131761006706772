import React from 'react';
import {
  Show,
  TextField,
  DateField,
  NumberField,
  Link,
  FunctionField,
  RichTextField,
  BooleanField,
  TabbedShowLayout,
} from 'react-admin';
import LessonsTab from './LessonsTab';
import CoursePricesTab from './CoursePricesTab';

const ShowPage = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Курс">
        <TextField source="id" />
        <TextField source="title" />
        <RichTextField source="description" />
        <BooleanField source="feedBackAvailable" />
        <NumberField source="feedBackPrice" />
        <DateField source="createdAt" />
        <FunctionField
          label="Пользователь"
          render={(record) =>
            record.user ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/User/${record.user.id}/show`}
              >{`${record.user.firstName} ${record.user.lastName}`}</Link>
            ) : null
          }
        />
        <FunctionField
          label="Автор"
          render={(record) =>
            record.author ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/Author/${record.author.id}/show`}
              >{`${record.author.name}`}</Link>
            ) : null
          }
        />
        <FunctionField
          label="Студенческая группа"
          render={(record) =>
            record.studentGroup ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/StudentGroup/${record.studentGroup.id}/show`}
              >{`${record.studentGroup.title}`}</Link>
            ) : null
          }
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Уроки">
        <LessonsTab />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Варианты цены">
        <CoursePricesTab />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export default ShowPage;

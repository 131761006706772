import { gql } from '@apollo/client';

export const SPENDING_FRAGMENT = gql`
  fragment SpendingFragment on Spending {
    id
    amount
    description
    currency
    userId
    createdAt
    spendingDate
    user {
      id
      firstName
      lastName
    }
  }
`;

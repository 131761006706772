import { gql } from '@apollo/client';
import { STUDENT_GROUP_FRAGMENT } from '../fragments/studentGroupFragment';

export const UPDATE_STUDENT_GROUP_MUTATION = gql`
  ${STUDENT_GROUP_FRAGMENT}
  mutation UpdateStudentGroup($id: ID!, $data: UpdateStudentGroupInput!) {
    updateStudentGroup(id: $id, data: $data) {
      ...StudentGroupFragment
    }
  }
`;

export const transform = async (data) => {
  if (data?.lessons) {
    data.lessons = data.lessons.map((i) => i.id);
  }

  if (data?.logoMedia) {
    data.logoMedia = data.logoMedia.rawFile;
  } else data.deleteLogo = true;

  return data;
};

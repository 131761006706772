import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Link,
  FunctionField,
} from 'react-admin';
import PrivateMediaSrc from './PrivateMediaSrc';

const MediaShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <FunctionField
          label="src"
          render={(record) =>
            record.src ? (
              <Link
                target="_blank"
                onClick={(e) => e.stopPropagation()}
                to={record.src}
              >
                {record.src}
              </Link>
            ) : null
          }
        />
        <TextField source="title" />
        <TextField source="mimeType" />
        <TextField source="acl" />
        <DateField source="createdAt" />
        <PrivateMediaSrc label="Private src" />
      </SimpleShowLayout>
    </Show>
  );
};

export default MediaShow;

import { gql } from '@apollo/client';
import { SPENDING_FRAGMENT } from '../fragments/spendingFragment';

export const CREATE_SPENDING_MUTATION = gql`
  ${SPENDING_FRAGMENT}
  mutation CreateSpending($input: CreateSpendingInput!) {
    createSpending(input: $input) {
      ...SpendingFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { CoursePriceFragment } from '../fragments/coursePriceFragment';

export const coursePrice = gql`
  ${CoursePriceFragment}
  query CoursePrice($id: ID!) {
    CoursePrice(id: $id) {
      ...CoursePriceFragment
    }
  }
`;

import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  RichTextField,
} from 'react-admin';
import ArticleStatusField from './ArticleStatusField';

const ShowPage = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" />
      <TextField source="title" />
      <TextField source="slug" />
      <RichTextField source="content" />
      <TextField source="metaDescription" />
      <TextField source="metaKeywords" />
      <ArticleStatusField source="status" />
    </SimpleShowLayout>
  </Show>
);

export default ShowPage;

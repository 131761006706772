import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Link,
  FunctionField,
  RichTextField,
} from 'react-admin';

const ShowPage = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" />
      <TextField source="lng" />
      <TextField source="title" />
      <RichTextField source="content" />
      <FunctionField
        label="Article"
        render={(record) =>
          record.article ? (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/Article/${record.article.id}/show`}
            >{`${record.article.title} ${record.article.title}`}</Link>
          ) : null
        }
      />
    </SimpleShowLayout>
  </Show>
);

export default ShowPage;

import { gql } from '@apollo/client';
import { LESSON_FRAGMENT } from '../fragments/lessonFragment';

export const LESSON_QUERY = gql`
  ${LESSON_FRAGMENT}
  query Lesson($id: ID!) {
    Lesson(id: $id) {
      ...LessonFragment
    }
  }
`;

import React from 'react';

import {
  List,
  Datagrid,
  TextInput,
  DateInput,
  DateField,
  TextField,
  Link,
  FunctionField,
} from 'react-admin';
import PaymentStatusField from '../Payment/PaymentStatusField';
import getPriceView from '../../utils/getPriceView';

const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <TextInput source="id" name="id" />,
        <TextInput source="userId" name="userId" />,
        <DateInput source="createdAt" name="createdAt" />,
      ]}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <DateField source="createdAt" />
        <FunctionField
          label="Статус платежа"
          render={(record) =>
            record.payment ? (
              <PaymentStatusField value={record.payment.status} />
            ) : null
          }
        />
        <TextField label="Статус заказа" source="status" />

        <FunctionField
          label="Total Price"
          render={(record) =>
            `${record.payment.currency} ${getPriceView(
              record.totalPrice,
              false
            )}`
          }
        />

        <FunctionField
          label="Пользователь"
          render={(record) =>
            record.user ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/User/${record.user.id}/show`}
              >{`${record.user.firstName} ${record.user.lastName}`}</Link>
            ) : null
          }
        />
      </Datagrid>
    </List>
  );
};

export default ListPage;

import { gql } from '@apollo/client';
import { SPENDING_FRAGMENT } from '../fragments/spendingFragment';

export const SPENDINGS_QUERY = gql`
  ${SPENDING_FRAGMENT}
  query Spendings(
    $filter: SpendingsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allSpendings(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...SpendingFragment
      }
      total
    }
  }
`;

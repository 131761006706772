import { gql } from '@apollo/client';
import { ArticleTranslationFragment } from '../fragments/ArticleTranslationFragment';

export const deleteArticleTranslation = gql`
  ${ArticleTranslationFragment}
  mutation DeleteArticleTranslation($id: ID!) {
    deleteArticleTranslation(id: $id) {
      ...ArticleTranslationFragment
    }
  }
`;

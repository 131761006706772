import { gql } from '@apollo/client';
import { USER_FRAGMENT } from './userFragment';

export const ProductGroupFragment = gql`
  ${USER_FRAGMENT}
  fragment ProductGroupFragment on ProductGroup {
    id
    title
    createdAt
    deletedAt
    user {
      ...UserFragment
    }
  }
`;

import React from 'react';
import CustomRichTextInputToolbar from './CustomRichTextInputToolbar';
import { RichTextInput } from 'ra-input-rich-text';

const CustomRichTextInput = (props) => {
  return (
    <RichTextInput
      toolbar={<CustomRichTextInputToolbar {...props} />}
      {...props}
    />
  );
};

export default CustomRichTextInput;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { NumberInput, SimpleForm } from 'react-admin';
import CustomSelectInput from '../CustomSelectInput';
const CreateUpdateForm = ({ title }) => {
  return (
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput source="days" fullWidth defaultValue={0} />
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput label="Price EUR" source="price" fullWidth />
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '50%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <CustomSelectInput
            label="Course"
            source="courseId"
            reference="Course"
          />
        </Box>
      </Box>
    </SimpleForm>
  );
};

export default CreateUpdateForm;

import { gql } from '@apollo/client';
import { ReviewFragment } from '../fragments/reviewFragment';

export const Review = gql`
  ${ReviewFragment}
  query Review($id: ID!) {
    Review(id: $id) {
      ...ReviewFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { MediaFragment } from '../fragments/mediaFragment';

export const Media = gql`
  ${MediaFragment}
  query Media($id: ID!) {
    Media(id: $id) {
      ...MediaFragment
    }
  }
`;

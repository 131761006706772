import { gql } from '@apollo/client';
import { PRODUCT_FRAGMENT } from '../fragments/productFragment';

export const PRODUCTS_QUERY = gql`
  ${PRODUCT_FRAGMENT}
  query Products(
    $filter: ProductsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allProducts(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...ProductFragment
      }
      total
    }
  }
`;

import * as React from 'react';
import { Edit } from 'react-admin';

import CreateUpdateForm from './CreateUpdateForm';
import { transformMedias } from '../../utils/transformMedias';

const ProductEdit = () => (
  <Edit mutationMode="pessimistic" transform={transformMedias}>
    <CreateUpdateForm title="Редагувати товар" />
  </Edit>
);

export default ProductEdit;

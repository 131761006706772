import React from 'react';
import { SelectInput, useGetList } from 'react-admin';

const CustomSelectInput = ({ source, reference, ...props }) => {
  const { data, loading } = useGetList(
    reference,
    { page: 1, perPage: 100 },
    { field: 'createdAt', order: 'DESC' }
  );

  const choices =
    data && data.length ? data.map((i) => ({ id: i.id, name: i.title })) : [];

  if (loading || !choices.length) return <div />;

  return <SelectInput fullWidth source={source} choices={choices} {...props} />;
};

export default CustomSelectInput;

import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateInput,
  DateField,
} from 'react-admin';

const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <TextInput source="name" name="name" />,
        <DateInput source="createdAt" name="createdAt" />,
      ]}
      {...props}
    >
      <Datagrid rowClick="edit">
        <DateField source="createdAt" label="Дата создания" />
        <TextField source="name" label="Имя" />
      </Datagrid>
    </List>
  );
};

export default ListPage;

import { gql } from '@apollo/client';
import { CoursePriceFragment } from '../fragments/coursePriceFragment';

export const updateCoursePrice = gql`
  ${CoursePriceFragment}
  mutation UpdateCoursePrice($id: ID!, $data: UpdateCoursePriceInput!) {
    updateCoursePrice(id: $id, data: $data) {
      ...CoursePriceFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { ProductGroupFragment } from '../fragments/productGroupFragment';

export const productGroup = gql`
  ${ProductGroupFragment}
  query ProductGroup($id: ID!) {
    ProductGroup(id: $id) {
      ...ProductGroupFragment
    }
  }
`;

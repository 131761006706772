import * as React from 'react';
import { Create } from 'react-admin';

import CreateUpdateForm from './CreateUpdateForm';

const StudentGroupCreate = () => {
  return (
    <Create mutationMode="pessimistic">
      <CreateUpdateForm title="Додати групу студентів" />
    </Create>
  );
};

export default StudentGroupCreate;

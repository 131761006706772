import React from 'react';
import useGeneratePresignedMediaUrl from '../../hooks/useGeneratePresignedMediaUrl';
import VideoPlayer from '../VideoPlayer';

const ShowLessonMedia = ({ media }) => {
  const { loading, url } = useGeneratePresignedMediaUrl(media);

  if (loading) {
    return <span>Loading...</span>;
  }

  return (
    <div style={{ maxWidth: '50vw' }}>
      <VideoPlayer url={url} />
    </div>
  );
};

export default ShowLessonMedia;

import React from 'react';
import { useRecordContext } from 'react-admin';
import { Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  display: 'inline-block',
}));

const ArticleStatusField = ({ source }) => {
  const record = useRecordContext();
  const value = record[source];

  const getSeverity = () => {
    switch (value) {
      case 'DRAFT':
        return 'info';
      case 'PUBLISHED':
        return 'success';
      case 'ARCHIVED':
        return 'warning';
      default:
        return undefined;
    }
  };

  return (
    <Root>
      <Alert severity={getSeverity()}>{value}</Alert>
    </Root>
  );
};

export default ArticleStatusField;

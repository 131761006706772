import React from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  NumberField,
  useRecordContext,
} from 'react-admin';

const OrderCourseTab = () => {
  const record = useRecordContext();
  return (
    <ArrayField source="orderCourses" record={record}>
      <Datagrid bulkActionButtons={null}>
        <DateField label="Дата создания" source="createdAt" />
        <FunctionField
          label="Курс"
          render={(record) =>
            record.course ? (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/Course/${record.course.id}/show`}
              >
                {record.course.title}
              </Link>
            ) : null
          }
        />
        <NumberField label="Цена" source="coursePriceValue" />
        <NumberField source="withFeedBack" />
        <NumberField source="courseFeedBackPrice" />
        <DateField label="Дата окончания доступа" source="accessEndsAt" />
      </Datagrid>
    </ArrayField>
  );
};

export default OrderCourseTab;

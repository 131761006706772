import { gql } from '@apollo/client';
import { CourseFragment } from './courseFragment';
import { PaymentFragment } from './paymentFragment';

export const OrderCourseFragment = gql`
  ${CourseFragment}
  ${PaymentFragment}
  fragment OrderCourseFragment on OrderCourse {
    id
    coursePriceValue
    accessEndsAt
    createdAt
    withFeedBack
    courseFeedBackPrice
    courseId
    course {
      ...CourseFragment
    }
    orderId
  }
`;

import React from 'react';

import {
  Filter,
  List,
  Datagrid,
  TextField,
  DateInput,
  DateField,
  NumberInput,
  NumberField,
  FunctionField,
  Link,
} from 'react-admin';
import CustomSelectInput from '../CustomSelectInput';

const Filters = (props) => (
  <Filter {...props}>
    <CustomSelectInput label="Course" source="courseId" reference="Course" />
    <NumberInput label="Days" source="days" />
    <NumberInput label="Price EUR" source="price" />
    <DateInput label="Дата создания" source="createdAt" />
  </Filter>
);

const ListPage = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={props.filters || <Filters />}
      {...props}
    >
      <Datagrid
        bulkActionButtons={props?.bulkActionButtons}
        data={props?.data}
        rowClick={props?.rowClick || 'edit'}
      >
        <DateField source="createdAt" />
        <TextField source="days" />
        <NumberField label="Price EUR" source="price" />
        {!props?.hideRows?.course && (
          <FunctionField
            source="course"
            label="Course"
            render={(record) =>
              record.course ? (
                <Link
                  onClick={(e) => e.stopPropagation()}
                  to={`/Course/${record.course.id}/show`}
                >
                  {record.course.title}
                </Link>
              ) : null
            }
          />
        )}
      </Datagrid>
    </List>
  );
};

export default ListPage;

import * as React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { Box, Typography } from '@mui/material';

const UserEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Редагування Користувача
      </Typography>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="firstName" fullWidth />
          <TextInput source="address" fullWidth />
          <TextInput source="city" fullWidth />
          <TextInput source="phone" fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="lastName" fullWidth />
          <TextInput source="email" fullWidth />
          <TextInput source="country" fullWidth />
          <TextInput source="postalCode" fullWidth />
          <TextInput source="state" fullWidth />
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '50%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <SelectInput
            fullWidth
            source="role"
            name="role"
            choices={[
              { id: 'user', name: 'User' },
              { id: 'admin', name: 'Admin' },
            ]}
          />
        </Box>
      </Box>
    </SimpleForm>
  </Edit>
);

export default UserEdit;

import { gql } from '@apollo/client';
import { PaymentFragment } from '../fragments/paymentFragment';

export const Payment = gql`
  ${PaymentFragment}
  query Article($id: ID!) {
    Payment(id: $id) {
      ...PaymentFragment
    }
  }
`;

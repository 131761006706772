import React from 'react';
import { Menu } from 'react-admin';

const MenuItem = ({ children, ...others }) => {
  return (
    <>
      <Menu.Item {...others} />
      {children && <div style={{ marginLeft: '1em' }}>{children}</div>}
    </>
  );
};

export default MenuItem;

import { gql } from '@apollo/client';
import { ArticleFragment } from '../fragments/ArticleFragment';

export const deleteArticle = gql`
  ${ArticleFragment}
  mutation DeleteArticle($id: ID!) {
    deleteArticle(id: $id) {
      ...ArticleFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { ArticleTranslationFragment } from '../fragments/ArticleTranslationFragment';

export const createArticleTranslation = gql`
  ${ArticleTranslationFragment}
  mutation CreateArticleTranslation($data: CreateArticleTranslationInput!) {
    createArticleTranslation(data: $data) {
      ...ArticleTranslationFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { CourseFragment } from '../fragments/courseFragment';

export const updateCourse = gql`
  ${CourseFragment}
  mutation UpdateCourse($id: ID!, $data: UpdateCourseInput!) {
    updateCourse(id: $id, data: $data) {
      ...CourseFragment
    }
  }
`;

import apolloClient from '../utils/apolloClient';
import { LOGIN_USER_MUTATION } from '../graphql/mutations/loginUser';
import { CURRENT_USER_QUERY } from '../graphql/queries/currentUser';

export const authProvider = {
  // send username and password to the auth server and get back credentials
  login: async (input) => {
    try {
      const res = await apolloClient.mutate({
        mutation: LOGIN_USER_MUTATION,
        variables: {
          input,
        },
      });

      const access_token = res?.data?.loginUser?.access_token;
      localStorage.setItem('access_token', access_token);
      return Promise.resolve(access_token);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  // when the dataProvider returns an error, check if this is an authentication error
  checkError: async (error) => {
    // console.log('checkError', error)
    const res = await apolloClient.query({
      query: CURRENT_USER_QUERY,
    });

    const user = res?.data?.currentUser;

    if (!user) return Promise.reject(error);

    return Promise.resolve(user);
  },
  // when the user navigates, make sure that their credentials are still valid
  checkAuth: async (params) => {
    // console.log('checkAuth',params)
    const res = await apolloClient.query({
      query: CURRENT_USER_QUERY,
    });

    const user = res?.data?.currentUser;

    if (!user) return Promise.reject(new Error('no user data'));

    return Promise.resolve(user);
  },
  // remove local credentials and notify the auth server that the user logged out
  logout: async (data) => {
    await apolloClient.resetStore();
    localStorage.removeItem('access_token');
    return Promise.resolve();
  },
  // get the user's profile
  getIdentity: (data) => {
    // console.log('getIdentity',data)
    return Promise.resolve();
  },
  // get the user permissions (optional)
  getPermissions: (data) => {
    // console.log('getPermissions',data)
    return Promise.resolve();
  },
};

import { gql } from '@apollo/client';
import { AuthorFragment } from '../fragments/authorFragment';

export const Author = gql`
  ${AuthorFragment}
  query Author($id: ID!) {
    Author(id: $id) {
      ...AuthorFragment
    }
  }
`;

import React from 'react';
import { SelectInput, SimpleForm, TextInput } from 'react-admin';
import CustomRichTextInput from '../CustomRichTextInput';
import { useWatch } from 'react-hook-form';
import CustomSelectInput from '../CustomSelectInput';
import articleTranslationLngChoices from '../../choices/articleTranslationLngChoices';
import { Typography } from '@mui/material';

const Component = () => {
  const title = useWatch({
    name: 'title',
  });

  const chatGptText = `Создай виртуозное содержание статьи под названием ${title} так-же дополни текст html тегами для красоты`;

  return (
    <>
      <CustomSelectInput source="articleId" reference="Article" />

      <SelectInput
        source="lng"
        choices={articleTranslationLngChoices}
        fullWidth
      />

      <TextInput source="title" fullWidth />

      <CustomRichTextInput
        chatGptText={chatGptText}
        source="content"
        fullWidth
      />
    </>
  );
};

const CreateUpdateForm = ({ title }) => {
  return (
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Component />
    </SimpleForm>
  );
};

export default CreateUpdateForm;

import { print } from 'graphql';

async function fetchCallback({ variables, mutation }) {
  const access_token = localStorage.getItem('access_token');

  const mutationString =
    typeof mutation === 'object' ? print(mutation) : mutation;

  const res = await fetch(process.env.REACT_APP_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({
      query: mutationString,
      variables,
    }),
  });

  const { data, errors } = await res.json();

  if (errors) {
    throw new Error('Request failed');
  }

  return data;
}

export default fetchCallback;

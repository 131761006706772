import { gql } from '@apollo/client';
import { LESSON_FRAGMENT } from '../fragments/lessonFragment';

export const UPDATE_LESSON_MUTATION = gql`
  ${LESSON_FRAGMENT}
  mutation UpdateLesson($id: ID!, $data: UpdateLessonInput!) {
    updateLesson(id: $id, data: $data) {
      ...LessonFragment
    }
  }
`;

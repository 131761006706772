import { gql } from '@apollo/client';
import { ArticleFragment } from '../fragments/ArticleFragment';

export const Article = gql`
  ${ArticleFragment}
  query Article($id: ID!) {
    Article(id: $id) {
      ...ArticleFragment
    }
  }
`;

import { gql } from '@apollo/client';
import { CoursePriceFragment } from '../fragments/coursePriceFragment';

export const allCoursePrices = gql`
  ${CoursePriceFragment}
  query AllCoursePrices(
    $filter: CoursePricesFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    allCoursePrices(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...CoursePriceFragment
      }
      total
    }
  }
`;

import { gql } from '@apollo/client';

export const PaymentProviderFragment = gql`
  fragment PaymentProviderFragment on PaymentProvider {
    createdAt
    id
    isEnabled
    mediaId
    slug
    title
    trxUrlTemplate
    websiteUrl
    updatedAt
    sortingOrder
    media {
      id
      src
      title
    }
  }
`;
